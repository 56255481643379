import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { Button, Form, Select, TimePicker, Space, Card } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { fetchPeriodicSchedulesById } from "../../services/periodicScheduleApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Day } from "../../models/PeriodicSchedules";
import dayjs from "dayjs";
import ButtonModal from "../Modal/ButtonModal";
import {
  useMutationCreatePeriodicSchedule,
  useMutationUpdatePeriodicSchedule,
} from "../../mutations/useMutationPeriodicSchedule";

import getNotification, {
  NotificationType,
} from "../Applications/ThemeNotification";
import FormMode from "../../models/Form";
import reactQueryKeys from "../../mutations/ReactQueryKeys";

const { Option } = Select;

interface PeriodicScheduleFormProps {
  mode: FormMode;
  appId: number;
  selectedPeriodicScheduleId?: number;
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  setLastRefreshTime: Dispatch<SetStateAction<Date>>;
  name: string;
  isActive: boolean;
}

const PeriodicScheduleForm = ({
  mode,
  appId,
  selectedPeriodicScheduleId,
  isModalOpen,
  setIsModalOpen,
  setLastRefreshTime,
  name,
  isActive,
}: PeriodicScheduleFormProps) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: [reactQueryKeys.PERIODIC_SCHEDULES_INFO],
    queryFn: () =>
      fetchPeriodicSchedulesById({
        scheduleId: selectedPeriodicScheduleId!,
      }),
    enabled: mode === FormMode.EDIT,
  });

  const createPeriodicScheduleMutation = useMutationCreatePeriodicSchedule(
    queryClient,
    setLastRefreshTime
  );
  const updatePeriodicScheduleMutation = useMutationUpdatePeriodicSchedule(
    queryClient,
    setLastRefreshTime
  );

  const fillOutTheForm = useCallback(async () => {
    if (mode === FormMode.EDIT && selectedPeriodicScheduleId) {
      const scheduleData = data!;
      const formattedDaySchedules = scheduleData.daySchedules.map(
        (daySchedule) => {
          return {
            ...daySchedule,
            hour: [
              dayjs(daySchedule.startHour, "HH:mm:ss"),
              dayjs(daySchedule.endHour, "HH:mm:ss"),
            ],
          };
        }
      );
      form.setFieldsValue({
        name: scheduleData.name,
        daySchedules: formattedDaySchedules,
      });
    }
  }, [selectedPeriodicScheduleId, mode, form, data]);

  useEffect(() => {
    if (!isLoading && mode === FormMode.EDIT) {
      form.resetFields();
      fillOutTheForm();
    }
  }, [
    isModalOpen,
    selectedPeriodicScheduleId,
    mode,
    form,
    data,
    fillOutTheForm,
    isLoading,
  ]);

  useEffect(() => {
    if (isModalOpen && mode === FormMode.ADD) {
      form.setFieldsValue({
        daySchedules: [{ day: undefined, startHour: null, endHour: null }],
      });
    }
  }, [isModalOpen, form, mode]);

  const onSubmitPeriodic = async (values: { daySchedules: any[] }) => {
    try {
      const formattedDaySchedules = values.daySchedules.map((schedule) => ({
        ...schedule,
        startHour: schedule.hour[0].format("HH:mm:ss"),
        endHour: schedule.hour[1].format("HH:mm:ss"),
      }));

      if (mode === FormMode.ADD) {
        createPeriodicScheduleMutation.mutate({
          appId: appId,
          name: name,
          state: isActive,
          daySchedules: formattedDaySchedules,
        });
      } else if (mode === FormMode.EDIT && selectedPeriodicScheduleId) {
        updatePeriodicScheduleMutation.mutate({
          scheduleId: selectedPeriodicScheduleId,
          name: name,
          state: isActive,
          daySchedules: formattedDaySchedules,
        });
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        getNotification({
          message:
            "A scheduling conflict occurred. Please choose a different time.",
          type: NotificationType.ERROR,
        });
      } else {
        getNotification({
          message: "An error occurred. Please try again.",
          type: NotificationType.ERROR,
        });
      }
    }
  };

  return (
    <>
      <Card>
        <Form form={form} onFinish={onSubmitPeriodic}>
          <Form.List name="daySchedules">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "day"]}
                      rules={[
                        { required: true, message: "Please select a day" },
                      ]}
                    >
                      <Select placeholder="Select a day">
                        {Object.values(Day).map((day) => (
                          <Option key={day} value={day}>
                            {day}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "hour"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select start and end hour",
                        },
                      ]}
                    >
                      <TimePicker.RangePicker format="HH:mm" />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Day Schedule
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </Card>
      <ButtonModal
        handleCancel={() => setIsModalOpen(false)}
        handleOk={() => {
          form.submit();
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default PeriodicScheduleForm;
