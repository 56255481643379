import { Breadcrumb, Tabs } from "antd";
import { useQuery } from "@tanstack/react-query";
import { fetchGroups } from "../../services/listGroupApi";
import { getApplicationDetails } from "../../services/listApplicationsApi";
import { useContext, useMemo } from "react";
import { ClusterContext } from "../HomePage/HomePage";

import { useLocation, useNavigate } from "react-router-dom";
import UnifiedSchedulePage from "../SchedulePage/SchedulePage";
import ApplicationResourcesPage from "./ApplicationResourcesPage";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";

const ApplicationsDetailPage = ({
  appId,
  groupId,
  mode,
}: {
  appId: number;
  groupId?: number;
  mode: "group" | "applications";
}) => {
  const { cluster } = useContext(ClusterContext);
  const navigate = useNavigate();
  const location = useLocation();
  const fromGroupsPage = useMemo(
    () => location?.state?.fromGroupsPage,
    [location]
  );

  const { data: applicationData } = useQuery({
    queryKey: [ReactQueryKeys.APPLICATION_DETAILS, appId],
    queryFn: () => getApplicationDetails(appId),
    enabled: mode === "applications",
  });

  const { data: groupsData } = useQuery({
    queryKey: [ReactQueryKeys.GROUPS, cluster.id],
    queryFn: () =>
      fetchGroups({
        page: 0,
        pageSize: 1000,
        isAdminPage: true,
        clusterId: cluster.id!,
      }),
    enabled: mode === "group",
  });

  const items = [
    {
      key: "1",
      label: "Resources",
      children: ApplicationResourcesPage({ appId, groupId }),
    },
    {
      key: "2",
      label: "Schedules",
      children: UnifiedSchedulePage({ appId }),
    },
  ];

  const generateBreadcrumbItems = () => {
    const breadcrumbItems = [];

    if (groupsData && applicationData && fromGroupsPage) {
      const groupOwner = groupsData.content.find((group) =>
        group.popApplications.some((app) => app.id === appId)
      );

      if (groupOwner) {
        breadcrumbItems.push(
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            title: <a onClick={() => navigate("/groups")}>Groups</a>,
          },
          {
            title: (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a onClick={() => navigate(`/group-details/${groupOwner.id}`)}>
                {groupOwner.name}
              </a>
            ),
          }
        );
      }
    }

    if (!location?.state?.fromGroupsPage) {
      breadcrumbItems.push({
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        title: <a onClick={() => navigate("/applications")}>Applications</a>,
      });
    }

    breadcrumbItems.push({
      title: applicationData?.name,
    });

    return breadcrumbItems;
  };

  const breadcrumbItems = generateBreadcrumbItems();

  return (
    <>
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <Tabs defaultActiveKey="1" items={items} />
    </>
  );
};

export default ApplicationsDetailPage;
