import { Typography } from "antd";
import { useEffect, useState } from "react";
import { getTimeElapsed } from "../../services/getTimeElapsed";

export function useLastUpdateTime() {
  const [lastRefreshTime, setLastRefreshTime] = useState(new Date());
  const [timeElapsed, setTimeElapsed] = useState(() =>
    getTimeElapsed(lastRefreshTime)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeElapsed(getTimeElapsed(lastRefreshTime));
    }, 60000);

    setTimeElapsed(getTimeElapsed(lastRefreshTime));

    return () => {
      clearInterval(intervalId);
    };
  }, [lastRefreshTime]);

  const LastUpdateTimeComponent = () => (
    <>
      <Typography.Text type="secondary">
        Last update :{" "}
        {lastRefreshTime.toLocaleString(undefined, {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
        {" - "}
        {timeElapsed}
      </Typography.Text>
      <br />
      <br />
    </>
  );

  return { setLastRefreshTime, LastUpdateTimeComponent };
}
