import axios from "axios";
import { Group } from "../models/Group";
import { Application } from "../models/Application";

export const getPopApplicationsNotInCluster = async (
  groupId: number
): Promise<Application[]> => {
  const result = await axios.get(`${Config.apiBaseUrl}/applications/group`, {
    params: {
      groupId: groupId,
    },
  });
  return result.data;
};

export const getGroup = async (groupId: number): Promise<Group> => {
  const result = await axios.get(`${Config.apiBaseUrl}/groups/${groupId}`);
  return result.data;
};

export const saveGroup = async ({
  groupName,
  popApplicationIds,
  clusterId,
}: {
  groupName: string;
  popApplicationIds: number[];
  clusterId: number;
}) =>
  axios.post(`${Config.apiBaseUrl}/groups`, {
    name: groupName,
    popApplicationIds: popApplicationIds,
    clusterId: clusterId,
  });

export const editGroup = async ({
  groupId,
  groupName,
  popApplicationIds,
  clusterId,
}: {
  groupId: number;
  groupName: string;
  popApplicationIds: number[];
  clusterId: number;
}) =>
  axios.patch(`${Config.apiBaseUrl}/groups/${groupId}`, {
    name: groupName,
    popApplicationIds: popApplicationIds,
    clusterId: clusterId,
  });
