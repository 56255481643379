enum ReactQueryKeys {
  CLUSTERS = "clusters",
  GROUPS = "groups",
  CRD = "CRD",
  CRD_LIST = "CRD_LIST",
  APPLICATIONS = "applications",
  APPLICATION_DETAILS = "applicationDetails",
  APP_RESOURCES = "appResources",
  USERS = "users",
  SCHEDULES = "schedules",
  APP_SCHEDULES = "appSchedules",
  APP_SCHEDULE_INFO = "appScheduleInfo",
  PERIODIC_SCHEDULES = "periodicSchedules",
  PERIODIC_SCHEDULES_INFO = "periodicScheduleInfo",
}

export default ReactQueryKeys;
