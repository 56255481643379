import { QueryClient, useMutation } from "@tanstack/react-query";
import {
  removeApplication,
  setReplicasResources,
  syncBackendWithDB,
  toggleApplication,
  toggleResource,
} from "../services/listApplicationsApi";
import getNotification, {
  NotificationType,
} from "../components/Applications/ThemeNotification";
import {
  editApplication,
  saveApplication,
} from "../services/manageApplicationApi";
import ReactQueryKeys from "./ReactQueryKeys";

export const useMutationDeleteApplication = (queryClient: QueryClient) =>
  useMutation(removeApplication, {
    onError: () =>
      getNotification({
        message: "Removing application failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APPLICATIONS]);
      getNotification({
        message: "Application removed successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationHandleResourceReplicas = (queryClient: QueryClient) =>
  useMutation(setReplicasResources, {
    onError: () =>
      getNotification({
        message: "Changing resource replicas failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APP_RESOURCES]).then(() =>
        getNotification({
          message: "Resource updated successfully",
          type: NotificationType.SUCCESS,
        })
      );
    },
  });

export const useMutationSyncApp = (
  queryClient: QueryClient,
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date>>
) =>
  useMutation(syncBackendWithDB, {
    onError: () =>
      getNotification({
        message: "Application synchronisation failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APP_RESOURCES]);
      queryClient.invalidateQueries([ReactQueryKeys.APPLICATIONS]);
      setLastRefreshTime(new Date());
      getNotification({
        message: "Applications refreshed successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationResourceSwitch = (queryClient: QueryClient) =>
  useMutation(toggleResource, {
    onError: () =>
      getNotification({
        message: "Changing resource status failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APP_RESOURCES]).then(() => {
        getNotification({
          message: "Resource updated successfully",
          type: NotificationType.SUCCESS,
        });
      });
    },
  });

export const useMutationHandleAppSwitch = (
  queryClient: QueryClient,
  updateSwitchingState: (
    appId: number,
    switching: boolean,
    groupId?: number
  ) => void
) =>
  useMutation(toggleApplication, {
    onMutate: (variables) => {
      updateSwitchingState(variables.appId, true, variables.groupId);
    },
    onError: (_error, variables) => {
      updateSwitchingState(variables.appId, false, variables.groupId);
      getNotification({
        message: "Updating application failed",
        type: NotificationType.ERROR,
      });
    },
    onSuccess: (_data, variables) => {
      updateSwitchingState(variables.appId, false);
      queryClient.removeQueries([ReactQueryKeys.APPLICATIONS]);
      queryClient.invalidateQueries(["group"]);
      queryClient.invalidateQueries(["groups"]);
      getNotification({
        message: "Application successfully updated",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationEditApplication = (queryClient: QueryClient) =>
  useMutation(editApplication, {
    onError: () =>
      getNotification({
        message: "Application update failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APPLICATIONS]).then(() => {
        getNotification({
          message: "Application updated successfully",
          type: NotificationType.SUCCESS,
        });
      });
    },
  });

export const useMutationSaveApplication = (queryClient: QueryClient) =>
  useMutation(saveApplication, {
    onError: () =>
      getNotification({
        message: "Application creation failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APPLICATIONS]).then(() => {
        getNotification({
          message: "Application creation successfully",
          type: NotificationType.SUCCESS,
        });
      });
    },
  });
