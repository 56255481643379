import { notification } from "antd";
import { DARK_BACKGROUND_COLOR, WHITE_COLOR } from "../../assets/color";

export enum NotificationType {
  SUCCESS,
  ERROR,
}

const themeNotification = ({
  message,
  dark,
}: {
  message: string;
  dark: boolean;
}) => {
  return dark
    ? {
        message: <div style={{ color: WHITE_COLOR }}> {message} </div>,
        style: { backgroundColor: DARK_BACKGROUND_COLOR },
      }
    : { message: message };
};

const getNotification = ({
  message,
  type,
}: {
  message: string;
  type: NotificationType;
}) => {
  const isDarkMode = localStorage.getItem("darkMode") === "true";
  if (type === NotificationType.SUCCESS) {
    return notification.success(
      themeNotification({
        message: message,
        dark: isDarkMode,
      })
    );
  } else {
    return notification.error(
      themeNotification({
        message: message,
        dark: isDarkMode,
      })
    );
  }
};

export default getNotification;
