import React from "react";

import { Spin } from "antd";

const Loading: React.FC = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <Spin size="large">
      <div className="content" />
    </Spin>
  </div>
);

export default Loading;
