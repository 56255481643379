import axios from "axios";
import { Application } from "../models/Application";
import { Page } from "../models/Page";

export const getApplications = async ({
  page,
  pageSize,
  isAdminPage,
  clusterId,
}: {
  page: number;
  pageSize: number;
  isAdminPage: boolean;
  clusterId?: number;
}): Promise<Page<Application>> => {
  const result = await axios.get<Page<Application>>(
    `${Config.apiBaseUrl}/applications?page=${page}&size=${pageSize}`,
    {
      params: {
        clusterId: clusterId,
        isAdminPage: isAdminPage,
      },
    }
  );
  return result.data;
};

export const getApplicationDetails = async (
  appId: number
): Promise<Application> => {
  const result = await axios.get<Application>(
    `${Config.apiBaseUrl}/applications/${appId}`
  );
  return result.data;
};

export const toggleApplication = async ({
  isActive,
  appId,
  groupId,
}: {
  isActive: boolean;
  appId: number;
  groupId?: number;
}) => {
  await axios.post(
    `${Config.apiBaseUrl}/applications/${appId}/status`,
    {
      isActive,
    },
    {
      params: {
        groupId: groupId,
      },
    }
  );
};

export const toggleResource = async ({
  resourceId,
  isActive,
  appId,
  groupId,
}: {
  resourceId: number;
  appId: number;
  isActive: boolean;
  groupId?: number;
}) => {
  await axios.post(
    `${Config.apiBaseUrl}/resources/${resourceId}/status`,
    {
      isActive,
    },
    {
      params: {
        appId: appId,
        groupId: groupId,
      },
    }
  );
};

export const setReplicasResources = async ({
  replicasAmount,
  resourceId,
  groupId,
}: {
  replicasAmount: number;
  resourceId: number;
  groupId?: number;
}) => {
  await axios.patch(
    `${Config.apiBaseUrl}/resources/${resourceId}/replicas`,
    {
      replicasAmount,
    },
    {
      params: {
        groupId: groupId,
      },
    }
  );
};

export const syncBackendWithDB = async () => {
  await axios.post(`${Config.apiBaseUrl}/sync`);
};

export const removeApplication = async (appId: number) => {
  await axios.delete(`${Config.apiBaseUrl}/applications/${appId}`);
};
