import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Page } from "../models/Page";

export interface PaginationOptions {
  enable?: boolean;
}

const usePagination = <T>(
  searchFunction: (page: number, pageSize: number) => Promise<Page<T>>,
  queryKey: string,
  ...OtherQueryKeys: unknown[]
) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data, isLoading, isError, isFetched } = useQuery({
    queryKey: [queryKey, page, pageSize, ...OtherQueryKeys],
    queryFn: () => searchFunction(page - 1, pageSize),
  });

  return {
    page,
    items: data?.content,
    total: data?.totalElements,
    setPage,
    pageSize,
    setPageSize,
    isError,
    isLoading,
    isFetched,
  };
};

export default usePagination;
