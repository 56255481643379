import { Button, List, Switch, Typography } from "antd";
import React, { useContext } from "react";
import { SettingsContext } from "../../components/Context/SettingsContext";
import { KeycloakAuthenticatorContext } from "../../components/Context/KeyCloakAuthenticator/KeyCloakAuthenticator";

const SettingsPage = () => {
  const { isDarkMode, toggleTheme } = useContext(SettingsContext);
  const { logout } = useContext(KeycloakAuthenticatorContext);

  const data = [
    {
      key: "1",
      title: "Dark Mode",
    },
  ];

  return (
    <>
      <List
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <Typography style={{ marginRight: "16px" }}>
                {item.title}
              </Typography>
              <Switch checked={isDarkMode} onChange={toggleTheme} />
            </div>
            <Button
              type="text"
              onClick={() => logout()}
              style={{ marginTop: "8px" }}
              block
            >
              Logout
            </Button>
          </List.Item>
        )}
      />
    </>
  );
};

export default SettingsPage;
