import axios from "axios";
import { CRD } from "../models/CRD";
import { Page } from "../models/Page";

export const getCRDsInCluster = async ({
  page,
  pageSize,
  clusterId,
}: {
  page: number;
  pageSize: number;
  clusterId: number;
}): Promise<Page<CRD>> => {
  const result = await axios.get(
    `${Config.apiBaseUrl}/crds/cluster?page=${page}&size=${pageSize}`,
    {
      params: {
        clusterId: clusterId,
      },
    }
  );
  return result.data;
};

export const getCRD = async (crdId: number): Promise<CRD> => {
  const result = await axios.get(`${Config.apiBaseUrl}/crds/${crdId}`);
  return result.data;
};

export const saveCRD = ({
  groupName,
  groupVersion,
  pluralKind,
  pathReplicas,
  clusterId,
}: {
  groupName: string;
  groupVersion: string;
  pluralKind: string;
  clusterId: number;
  pathReplicas: string;
}) => {
  return axios.post(`${Config.apiBaseUrl}/crds`, {
    groupName: groupName,
    groupVersion: groupVersion,
    pluralKind: pluralKind,
    clusterId: clusterId,
    pathReplicas: pathReplicas,
  });
};

export const editCRD = async ({
  crdId,
  groupName,
  groupVersion,
  pluralKind,
  clusterId,
  pathReplicas,
}: {
  crdId: number;
  groupName: string;
  groupVersion: string;
  pluralKind: string;
  clusterId: number;
  pathReplicas: string;
}) => {
  return axios.patch(`${Config.apiBaseUrl}/crds/${crdId}`, {
    groupName: groupName,
    groupVersion: groupVersion,
    pluralKind: pluralKind,
    clusterId: clusterId,
    pathReplicas: pathReplicas,
  });
};

export const removeCRD = async (crdID: number) => {
  await axios.delete(`${Config.apiBaseUrl}/crds/${crdID}`);
};
