import { Button, Card, Col, Divider, Input, Row, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import useItemSelector, { FilterOperation } from "../../hooks/useItemsSelector";
import { Application } from "../../models/Application";
import { ClusterContext } from "../../pages/HomePage/HomePage";
import {
  getApplication,
  getIngresses,
  getIngressesNotInApplication,
} from "../../services/manageApplicationApi";

import { useNavigate } from "react-router-dom";
import FormMode from "../../models/Form";
import { Ingress } from "../../models/Ingress";

interface ApplicationsFormProps {
  mode: FormMode;
  appId?: number;
  prev: () => void;
  process: (ingressIds: number[]) => void;
}

const AddIngressForm = ({
  mode,
  appId,
  prev,
  process,
}: ApplicationsFormProps) => {
  const [ingressSearchString, setResourceSearchString] = useState("");

  const { cluster } = useContext(ClusterContext);
  const ingressSelector = useItemSelector<Ingress>();
  const navigate = useNavigate();

  useEffect(() => {
    if (ingressSearchString !== "") {
      ingressSelector.setFilterOnProperty({
        key: "name",
        value: ingressSearchString,
        operation: FilterOperation.CONTAINS,
      });
    } else {
      ingressSelector.clearFilterOnProperty("name");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingressSearchString]);

  useEffect(() => {
    if (mode === FormMode.EDIT) {
      getIngressesNotInApplication(appId!).then((data: Ingress[]) =>
        ingressSelector.initItems(data)
      );
    } else {
      getIngresses(cluster.id!).then((data: Ingress[]) =>
        ingressSelector.initItems(data)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster.id, appId, mode]);

  useEffect(
    () => {
      if (mode === FormMode.EDIT) {
        getApplication(appId!).then((data: Application) => {
          ingressSelector.setSelectedItems(data.ingresses!);
        });
      } else {
        getIngresses(cluster.id!).then((data) =>
          ingressSelector.initItems(data)
        );
        ingressSelector.setSelectedItems([]);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appId, mode]
  );

  const selectResource = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const selectedResourceId = event.currentTarget.id;
    ingressSelector.selectItem(selectedResourceId);
  };

  const unSelectResource = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const selectedResourceId = event.currentTarget.id;
    ingressSelector.unSelectItem(selectedResourceId);
  };

  const handleSubmit = () => {
    const ingressIds = ingressSelector.selectedItems.map((res) => res.id);
    process(ingressIds);
    navigate("/manage-app");
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Typography.Title level={4}>Available Ingresses</Typography.Title>

          <Card>
            <div>
              <Input
                placeholder="Filter ressource"
                value={ingressSearchString}
                onChange={(e) => setResourceSearchString(e.target.value)}
                style={{ width: "60%" }}
              />
            </div>

            <Divider />

            {ingressSelector.availableItems.map((res) => (
              <Button
                key={res.id}
                id={res.id.toString()}
                onClick={selectResource}
                style={{ marginRight: "8px", marginBottom: "8px" }}
              >
                {res.name}
              </Button>
            ))}
          </Card>
        </Col>

        <Col span={12}>
          <Typography.Title level={4}>Selected Ingresses</Typography.Title>

          <Card>
            {ingressSelector.selectedItems.length === 0 ? (
              <Typography.Text
                type="secondary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                No selected ingress
              </Typography.Text>
            ) : (
              ingressSelector.selectedItems.map((res) => (
                <Button
                  key={res.id}
                  id={res.id.toString()}
                  onClick={unSelectResource}
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                >
                  {res.name}
                </Button>
              ))
            )}
          </Card>
        </Col>
      </Row>
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Button onClick={() => navigate("/manage-app")}>Cancel</Button>
        </div>
        <Button style={{ margin: "0 8px" }} onClick={prev}>
          Previous
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </>
  );
};

export default AddIngressForm;
