import { useEffect } from "react";
import { getCluster } from "../../services/listCluster";
import { UploadOutlined } from "@ant-design/icons";
import FormMode from "../../models/Form";
import { Checkbox, Input, Upload } from "antd";

interface ClusterFormProps {
  clusterId?: number;
  mode: FormMode;
  isSensitive: boolean;
  setIsSensitive: React.Dispatch<React.SetStateAction<boolean>>;
  clusterName: string;
  setClusterName: React.Dispatch<React.SetStateAction<string>>;
  handleUpload: (file: File) => boolean;
}

const AddEditClusterForm = ({
  clusterId,
  mode,
  isSensitive,
  setIsSensitive,
  clusterName,
  setClusterName,
  handleUpload,
}: ClusterFormProps) => {
  useEffect(() => {
    if (mode === FormMode.EDIT && clusterId) {
      getCluster(clusterId).then((data) => {
        setClusterName(data.name);
        setIsSensitive(data.sensitive);
      });
    } else {
      setClusterName("");
      setIsSensitive(false);
    }
    //eslint-disable-next-line
  }, [mode, clusterId]);

  return (
    <>
      <br />
      <Input
        placeholder="Enter cluster name"
        value={clusterName}
        onChange={(e) => setClusterName(e.target.value)}
      />
      <br />
      <br />
      <Checkbox
        onChange={(e) => setIsSensitive(e.target.checked)}
        checked={isSensitive}
      >
        Production Cluster
      </Checkbox>
      <br />
      <br />
      <Upload.Dragger
        beforeUpload={(file) => {
          handleUpload(file as File);
          return false;
        }}
        showUploadList={true}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag kube config file to this area to upload
        </p>
      </Upload.Dragger>
    </>
  );
};

export default AddEditClusterForm;
