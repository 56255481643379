import { Breadcrumb, Button, Card, ConfigProvider, Typography } from "antd";
import { useContext, useState } from "react";
import { ClusterContext } from "../HomePage/HomePage";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Application } from "../../models/Application";
import { useMutationUpdateUserRights } from "../../mutations/useMutationUser";
import { Group } from "../../models/Group";
import EditUserForm from "../../components/Users/EditUserForm";

const EditUserPage = ({ userId }: { userId: number | undefined }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [selectedPopApplications, setSelectedPopApplications] = useState<
    Application[]
  >([]);
  const [email, setEmail] = useState("");
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [keycloakId, setKeycloakId] = useState("");
  const { cluster } = useContext(ClusterContext);
  const queryClient = useQueryClient();

  const updateUserRightsMutation = useMutationUpdateUserRights(queryClient);

  const handleEdit = () => {
    const popApplicationIds = selectedPopApplications.map((res) => res.id);
    const groupIds = selectedGroups.map((res) => res.id);
    if (cluster.id !== null && userId !== undefined) {
      updateUserRightsMutation.mutate({
        userId,
        userName,
        email,
        keycloakId,
        popApplicationIds,
        groupIds,
      });
      navigate("/manage-users");
    }
  };

  const generateBreadcrumbItems = () => {
    const breadcrumbItems = [
      {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        title: <a onClick={() => navigate("/manage-users")}>Manage Users</a>,
      },
    ];

    return breadcrumbItems;
  };

  const breadcrumbItems = generateBreadcrumbItems();

  return (
    <>
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <br />
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              fontSize: 20,
              controlHeight: 40,
            },
          },
        }}
      ></ConfigProvider>

      <br />

      <Card
        title={<Typography.Title level={1}>Edit Group Access</Typography.Title>}
      >
        <EditUserForm
          userId={userId}
          setUserName={setUserName}
          setEmail={setEmail}
          setKeycloakId={setKeycloakId}
          selectedPopApplications={selectedPopApplications}
          setSelectedPopApplications={setSelectedPopApplications}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
        />
      </Card>
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Button onClick={() => navigate("/manage-users")}>Cancel</Button>
        </div>
        <Button type="primary" onClick={handleEdit}>
          Save
        </Button>
      </div>
    </>
  );
};

export default EditUserPage;
