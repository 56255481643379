import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Checkbox, Form, Input, Modal, Radio, Typography } from "antd";
import { UnifiedSchedule } from "../../models/UnifiedSchedule";
import ScheduleForm from "./ScheduleFormPage";
import PeriodicScheduleForm from "./PeriodicScheduleForm";
import FormMode from "../../models/Form";

const CombinedScheduleFormPage = ({
  mode,
  appId,
  schedule,
  isModalOpen = false,
  setIsModalOpen,
  setLastRefreshTime,
}: {
  mode: FormMode;
  appId: number;
  schedule?: UnifiedSchedule;
  isModalOpen?: boolean;
  setIsModalOpen: (open: boolean) => void;
  setLastRefreshTime: Dispatch<SetStateAction<Date>>;
}) => {
  const [isPeriodic, setIsPeriodic] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [name, setName] = useState("");

  const title = useMemo(
    () => (
      <Typography.Title level={1}>
        {mode === FormMode.ADD ? "Add Schedule" : "Edit Schedule"}
      </Typography.Title>
    ),
    [mode]
  );

  useEffect(() => {
    if (mode === FormMode.EDIT) {
      setIsPeriodic(schedule?.type === "PeriodicSchedule");
      setName(schedule!.name);
    }
  }, [mode, schedule]);

  return (
    <Modal
      title={title}
      width={1500}
      open={isModalOpen}
      footer={null}
      okText="Save"
      onCancel={() => setIsModalOpen(false)}
    >
      <Input
        placeholder="Enter schedule name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <br />

      {
        <Form.Item
          label="State"
          tooltip="The application state will be set between the start date and the end date. This state is reversed at the schedule's end date."
        >
          <Radio.Group
            onChange={(e) => setIsActive(e.target.value)}
            value={isActive}
          >
            <Radio value={true}>On</Radio>
            <Radio value={false}>Off</Radio>
          </Radio.Group>
        </Form.Item>
      }

      <Checkbox
        onChange={(e) => setIsPeriodic(e.target.checked)}
        disabled={mode === FormMode.EDIT}
      >
        Periodic Schedule
      </Checkbox>

      <br />
      <br />
      {isPeriodic ? (
        <PeriodicScheduleForm
          mode={mode}
          appId={appId}
          selectedPeriodicScheduleId={schedule?.id}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setLastRefreshTime={setLastRefreshTime}
          name={name}
          isActive={isActive}
        />
      ) : (
        <ScheduleForm
          mode={mode}
          appId={appId}
          scheduleId={schedule?.id}
          setIsModalOpen={setIsModalOpen}
          setLastRefreshTime={setLastRefreshTime}
          name={name}
          isActive={isActive}
        />
      )}
    </Modal>
  );
};

export default CombinedScheduleFormPage;
