import { Alert } from "antd";

const DangerZone = ({ isSensitive }: { isSensitive: boolean }) => {
  return isSensitive ? (
    <>
      {" "}
      <Alert
        description="You are on a sensitive environment. Be careful with actions you take here."
        type="warning"
        showIcon
      />
      <br />
    </>
  ) : (
    <></>
  );
};

export default DangerZone;
