import React, { createContext, useState, useEffect } from "react";

export interface SettingsContextProps {
  isDarkMode: boolean | undefined;
  toggleTheme: () => void;
}

const initSettings = {
  isDarkMode: undefined,
  //TODO toggle theme is not used
  toggleTheme: () => {},
};

export const SettingsContext =
  createContext<SettingsContextProps>(initSettings);

export const SettingsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );

  const toggleTheme = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem("darkMode", newMode.toString());
  };

  useEffect(() => {
    setIsDarkMode(localStorage.getItem("darkMode") === "true");
  }, []);

  return (
    <SettingsContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </SettingsContext.Provider>
  );
};
