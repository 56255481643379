import { QueryClient, useMutation } from "@tanstack/react-query";
import { editCRD, removeCRD, saveCRD } from "../services/manageCRDsAPI";
import { syncBackendWithDB } from "../services/listApplicationsApi";

import getNotification, {
  NotificationType,
} from "../components/Applications/ThemeNotification";

import ReactQueryKeys from "./ReactQueryKeys";

export const useHandleSaveMutation = (queryClient: QueryClient) =>
  useMutation(saveCRD, {
    onError: () =>
      getNotification({
        message: "Adding CRD failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.CRD_LIST]);
      getNotification({
        message: "CRD added successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useHandleEditMutation = (queryClient: QueryClient) =>
  useMutation(editCRD, {
    onError: () =>
      getNotification({
        message: "Updating CRD failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.CRD_LIST]);
      getNotification({
        message: "CRD updated successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useHandleRemoveCRD = (queryClient: QueryClient) =>
  useMutation(removeCRD, {
    onError: () =>
      getNotification({
        message: "Removing CRD failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.CRD_LIST]);
      getNotification({
        message: "CRD removed successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useHandleSyncCRD = (
  queryClient: QueryClient,
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date>>
) =>
  useMutation(syncBackendWithDB, {
    onError: () =>
      getNotification({
        message: "CRDs synchronisation failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([
        ReactQueryKeys.CLUSTERS,
        ReactQueryKeys.CRD_LIST,
      ]);
      setLastRefreshTime(new Date());
      getNotification({
        message: "Cluster refreshed successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });
