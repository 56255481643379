import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { useContext } from "react";
import { KeycloakAuthenticatorContext } from "../Context/KeyCloakAuthenticator/KeyCloakAuthenticator";
import Role from "../../models/Role";

const Navigation = () => {
  const location = useLocation();
  const current =
    location.pathname === "/" || location.pathname === ""
      ? "/"
      : "/" + location.pathname.split("/")[1];
  const navigate = useNavigate();

  const { roles } = useContext(KeycloakAuthenticatorContext);

  const items: Array<{
    type: "group";
    label: string;
    className?: string;
    children: Array<{
      label: string;
      key: string;
      onClick: () => void;
    }>;
  }> = [
    {
      type: "group",
      label: "User space",
      children: [
        {
          label: "Applications",
          key: "/applications",
          onClick: () => navigate("/applications"),
        },
        {
          label: "Groups",
          key: "/groups",
          onClick: () => navigate("/groups"),
        },
        {
          label: "Agenda",
          key: "/agenda",
          onClick: () => navigate("/agenda"),
        },
      ],
    },
  ];

  if (roles.includes(Role.ADMIN)) {
    items.push({
      type: "group",
      label: "Admin space",
      children: [
        {
          label: "Manage applications",
          key: "/manage-app",
          onClick: () => navigate("/manage-app"),
        },
        {
          label: "Manage CRDs",
          key: "/manage-crds",
          onClick: () => navigate("/manage-crds"),
        },
        {
          label: "Manage Groups",
          key: "/manage-group",
          onClick: () => navigate("/manage-groups"),
        },
        {
          label: "Manage clusters",
          key: "/manage-clusters",
          onClick: () => navigate("/manage-clusters"),
        },
        {
          label: "Manage Users",
          key: "/manage-users",
          onClick: () => navigate("/manage-users"),
        },
      ],
    });
  }

  return (
    <Menu
      style={{ borderRight: "none", background: "transparent" }}
      selectedKeys={[current]}
      items={items}
    />
  );
};

export default Navigation;
