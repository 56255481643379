import axios from "axios";
import {
  AppSchedule,
  AppScheduleWithApplication,
} from "../models/AppSchedules";

export const fetchSchedulesByApplication = async (
  appId: number
): Promise<AppSchedule[]> => {
  const result = await axios.get<AppSchedule[]>(
    `${Config.apiBaseUrl}/applications/${appId}/schedules`
  );
  return result.data;
};

export const fetchAllSchedules = async (
  clusterId: number
): Promise<AppScheduleWithApplication[]> => {
  try {
    const response = await axios.get<AppScheduleWithApplication[]>(
      `${Config.apiBaseUrl}/applications/schedules`,
      {
        params: {
          clusterId: clusterId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch schedules: ${error}`);
  }
};

export const fetchSchedulesById = async ({
  scheduleId,
}: {
  scheduleId: number;
}): Promise<AppSchedule> => {
  const result = await axios.get<AppSchedule>(
    `${Config.apiBaseUrl}/applications/schedules/${scheduleId}`
  );
  return result.data;
};

export const createSchedule = async ({
  appId,
  schedule,
}: {
  appId: number;
  schedule: {
    name: string;
    isActive: boolean;
    startDate: string;
    endDate: string;
  };
}): Promise<AppSchedule[]> => {
  const result = await axios.post<AppSchedule[]>(
    `${Config.apiBaseUrl}/applications/${appId}/schedule`,
    {
      name: schedule.name,
      isActive: schedule.isActive,
      startDate: schedule.startDate,
      endDate: schedule.endDate,
    }
  );
  return result.data;
};

export const updateSchedules = async ({
  scheduleId,
  schedule,
}: {
  scheduleId: number;
  schedule: {
    name: string;
    isActive: boolean;
    startDate: string;
    endDate: string;
  };
}): Promise<AppSchedule[]> => {
  const result = await axios.patch<AppSchedule[]>(
    `${Config.apiBaseUrl}/applications/schedules/${scheduleId}`,
    {
      name: schedule.name,
      isActive: schedule.isActive,
      startDate: schedule.startDate,
      endDate: schedule.endDate,
    }
  );
  return result.data;
};

export const deleteSchedules = async ({
  scheduleId,
}: {
  scheduleId: number;
}): Promise<AppSchedule[]> => {
  const result = await axios.delete<AppSchedule[]>(
    `${Config.apiBaseUrl}/applications/schedules/${scheduleId}`
  );
  return result.data;
};
