import React, { useState } from "react";
import { Button, FloatButton, Table, Tooltip } from "antd";
import { fetchGroups } from "../../services/listGroupApi";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { ClusterContext } from "../HomePage/HomePage";
import { ColumnsType } from "antd/es/table";
import { useQueryClient } from "@tanstack/react-query";
import Loading from "../../components/LoadingAndError/Loading";
import Error from "../../components/LoadingAndError/Error";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import { useSync } from "../../mutations/useMutationCluster";
import { Group } from "../../models/Group";
import { useDeleteGroupMutation } from "../../mutations/useMutationGroups";
import DeleteModal from "../../components/Modal/DeleteModal";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import usePagination from "../../hooks/usePagination";
import DangerZone from "../../components/DangerZone/DangerZone";
import { useNavigate } from "react-router-dom";

const ManageGroupsPage = () => {
  const { cluster } = React.useContext(ClusterContext);
  const queryClient = useQueryClient();
  const { setLastRefreshTime, LastUpdateTimeComponent } = useLastUpdateTime();
  const navigate = useNavigate();

  const groups = usePagination(
    (page, pageSize) =>
      fetchGroups({
        page: page,
        pageSize: pageSize,
        isAdminPage: true,
        clusterId: cluster.id!,
      }),
    ReactQueryKeys.GROUPS
  );

  const onChangePage = (newPage: number, newPageSize: number) => {
    groups.setPage(newPage);
    groups.setPageSize(newPageSize);
  };

  const handleSyncMutation = useSync(queryClient, setLastRefreshTime);
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(
    undefined
  );
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const handleRemoveGroupMutation = useDeleteGroupMutation(queryClient);

  const handleRemoveGroup = async (groupId: number) => {
    handleRemoveGroupMutation.mutate(groupId);
  };

  const handleSync = () => {
    handleSyncMutation.mutate();
  };

  const columns: ColumnsType<Group> = [
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip title="Synchronize">
            <Button icon={<SyncOutlined />} onClick={handleSync} />
          </Tooltip>
        </div>
      ),
      key: "actions",
      render: (text: string, record: Group) => (
        <>
          <Button
            onClick={() => {
              setSelectedGroupId(record.id);
              navigate(`/edit-group/${record.id}`);
            }}
          >
            Edit
          </Button>
          <span style={{ margin: "0 10px" }}></span>
          <Button
            type="primary"
            onClick={() => {
              setSelectedGroupId(record.id);
              setIsModalDeleteOpen(true);
            }}
          >
            Remove
          </Button>
        </>
      ),
      align: "right" as const,
    },
  ];
  return groups.isLoading ? (
    <Loading />
  ) : groups.isError ? (
    <Error height="80vh" />
  ) : (
    <>
      <LastUpdateTimeComponent />
      <DangerZone isSensitive={cluster.sensitive} />
      <Table
        columns={columns}
        dataSource={groups.items}
        rowKey="id"
        pagination={{
          total: groups.total,
          current: groups.page,
          pageSize: groups.pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onChangePage,
        }}
      />
      <FloatButton
        shape="circle"
        tooltip="Add Group"
        type="primary"
        style={{ right: 94, width: 60, height: 60 }}
        icon={<PlusOutlined />}
        onClick={() => {
          setSelectedGroupId(undefined);
          navigate("/add-group");
        }}
      />
      <DeleteModal
        id={selectedGroupId}
        isModalDeleteOpen={isModalDeleteOpen}
        setIsModalDeleteOpen={setIsModalDeleteOpen}
        handleRemove={handleRemoveGroup}
      />
    </>
  );
};

export default ManageGroupsPage;
