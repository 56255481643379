import { QueryClient, useMutation } from "@tanstack/react-query";

import getNotification, {
  NotificationType,
} from "../components/Applications/ThemeNotification";
import { syncBackendWithDB } from "../services/listApplicationsApi";
import ReactQueryKeys from "./ReactQueryKeys";

export const useMutationSyncGroup = (queryClient: QueryClient) =>
  useMutation(syncBackendWithDB, {
    onError: () =>
      getNotification({
        message: "Synchronizing failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      // TODO key non existent
      queryClient.invalidateQueries(["applicationsGroup"]);
      queryClient.invalidateQueries([ReactQueryKeys.GROUPS]);
    },
  });
