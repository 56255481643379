import { ConfigProvider, Layout, theme } from "antd";
import { Outlet } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { fetchClusters } from "../../services/listCluster";
import Footer from "../../components/Shell/Footer";
import Drawer from "../../components/Shell/Drawer";
import SynchronizeList from "../../components/SynchronizeList/SynchronizeList";
import Error from "../../components/LoadingAndError/Error";
import Loading from "../../components/LoadingAndError/Loading";
import { useMutationCreateUser } from "../../mutations/useMutationUser";
import { SettingsContext } from "../../components/Context/SettingsContext";

import usePagination from "../../hooks/usePagination";

import ReactQueryKeys from "../../mutations/ReactQueryKeys";

export interface ClusterContextType {
  cluster: {
    id: number | null;
    name: string | null;
    sensitive: boolean;
  };
  setCluster: React.Dispatch<
    React.SetStateAction<{
      id: number | null;
      name: string | null;
      sensitive: boolean;
    }>
  >;
  isDarkMode: boolean;
}

export const ClusterContext = React.createContext<ClusterContextType>({
  cluster: {
    id: null,
    name: null,
    sensitive: false,
  },
  setCluster: () => {},
  isDarkMode: false,
});

const HomePage = () => {
  const [currentCluster, setCurrentCluster] = useState<{
    id: number | null;
    name: string | null;
    sensitive: boolean;
  }>({
    id: null,
    name: null,
    sensitive: false,
  });

  const customDarkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
      colorPrimary: "#00a0ff",
    },
  };
  const { isDarkMode } = useContext(SettingsContext);

  const clusters = usePagination(
    () => fetchClusters(0, 1000),
    ReactQueryKeys.CLUSTERS
  );

  const createUserMutationTriggered = useRef(false);

  if (clusters.isFetched && clusters.items?.length) {
    const isCurrentClusterValid = clusters.items.some(
      (cluster) => cluster.id === currentCluster.id
    );

    if (!isCurrentClusterValid || !currentCluster.name) {
      setCurrentCluster({
        id: clusters.items.length > 0 ? clusters.items[0].id : null,
        name: clusters.items.length > 0 ? clusters.items[0].name : null,
        sensitive:
          clusters.items.length > 0 ? clusters.items[0].sensitive : false,
      });
    }
  }

  const handleCreateUserMutation = useMutationCreateUser();

  useEffect(() => {
    if (!createUserMutationTriggered.current) {
      handleCreateUserMutation.mutate();
      createUserMutationTriggered.current = true;
    }
  }, [handleCreateUserMutation]);

  return clusters.isLoading ? (
    <Loading />
  ) : clusters.isError ? (
    <Error height="80vh" />
  ) : (
    <>
      <ConfigProvider theme={isDarkMode ? customDarkTheme : {}}>
        <ClusterContext.Provider
          value={{
            cluster: currentCluster,
            setCluster: setCurrentCluster,
            isDarkMode: isDarkMode!,
          }}
        >
          <ConfigProvider>
            <div style={{ backgroundColor: isDarkMode ? "black" : "white" }}>
              <Drawer />
              <Layout style={{ minHeight: "100vh", marginLeft: 300 }}>
                <Layout.Content
                  style={{
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    paddingTop: "20px",
                  }}
                >
                  <SynchronizeList
                    clusters={clusters.items!}
                    currentCluster={currentCluster}
                    setCurrentCluster={setCurrentCluster}
                  />
                  <br />
                  <br />
                  <Outlet />
                </Layout.Content>
                <Footer />
              </Layout>
            </div>
          </ConfigProvider>
        </ClusterContext.Provider>
      </ConfigProvider>
    </>
  );
};

export default HomePage;
