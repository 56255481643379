import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Card, DatePicker, Form } from "antd";
import { Dispatch, SetStateAction, useEffect } from "react";
import { fetchSchedulesById } from "../../services/scheduleApi";
import dayjs, { Dayjs } from "dayjs";
import ButtonModal from "../Modal/ButtonModal";
import {
  useMutationCreateSchedule,
  useMutationUpdateSchedule,
} from "../../mutations/useMutationSchedule";
import FormMode from "../../models/Form";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";

interface ScheduleFormProps {
  mode: FormMode;
  appId: number;
  scheduleId?: number | null;
  setIsModalOpen: (open: boolean) => void;
  setLastRefreshTime: Dispatch<SetStateAction<Date>>;
  name: string;
  isActive: boolean;
}

export const ScheduleForm = ({
  mode,
  appId,
  scheduleId = null,
  setIsModalOpen,
  setLastRefreshTime,
  name,
  isActive,
}: ScheduleFormProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { data, isLoading } = useQuery(
    [ReactQueryKeys.APP_SCHEDULE_INFO, scheduleId],
    () =>
      fetchSchedulesById({
        scheduleId: scheduleId!,
      }),
    { enabled: mode === FormMode.EDIT }
  );

  const createAppScheduleMutation = useMutationCreateSchedule(
    queryClient,
    setLastRefreshTime
  );
  const updateAppScheduleMutation = useMutationUpdateSchedule(
    queryClient,
    setLastRefreshTime
  );

  const fillOutTheForm = async () => {
    if (mode === FormMode.EDIT && scheduleId) {
      const appSchedule = data!;
      form.setFieldsValue({
        isActive: isActive,
        name: appSchedule.name,
        startDate: dayjs(appSchedule.startDate),
        endDate: dayjs(appSchedule.endDate),
      });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      form.resetFields();
      fillOutTheForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId, mode, data, isLoading]);

  const onSubmitSimple = async (values: any) => {
    let { startDate, endDate } = values;
    startDate = startDate.second(0);
    endDate = endDate.second(0);
    const payload = {
      name: name,
      isActive,
      startDate: startDate.format(),
      endDate: endDate.format(),
    };

    if (mode === FormMode.ADD) {
      createAppScheduleMutation.mutate({
        appId: appId,
        schedule: payload,
      });
      setIsModalOpen(false);
    } else if (mode === FormMode.EDIT && scheduleId) {
      updateAppScheduleMutation.mutate({
        scheduleId: scheduleId,
        schedule: payload,
      });
      setIsModalOpen(false);
    }
  };

  const disabledStartDate = (startValue: Dayjs) => {
    const endValue = form.getFieldValue("endDate");
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (endValue: Dayjs) => {
    const startValue = form.getFieldValue("startDate");
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() < startValue.valueOf();
  };

  return (
    <>
      <Form form={form} name="schedule-form" onFinish={onSubmitSimple}>
        <Card>
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[{ required: true }]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              disabledDate={disabledStartDate}
            />
          </Form.Item>

          <Form.Item
            name="endDate"
            label="End Date"
            rules={[{ required: true }]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              disabledDate={disabledEndDate}
            />
          </Form.Item>
        </Card>
      </Form>
      <ButtonModal
        handleCancel={() => setIsModalOpen(false)}
        handleOk={() => form.submit()}
      />
    </>
  );
};

export default ScheduleForm;
