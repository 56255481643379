import axios from "axios";
import { Cluster } from "../models/Cluster";
import { Page } from "../models/Page";

export const fetchClusters = async (
  page: number,
  pageSize: number
): Promise<Page<Cluster>> => {
  const result = await axios.get<Page<Cluster>>(
    `${Config.apiBaseUrl}/clusters?page=${page}&size=${pageSize}`
  );
  return result.data;
};

export const getCluster = async (clusterId: number): Promise<Cluster> => {
  const result = await axios.get(`${Config.apiBaseUrl}/clusters/${clusterId}`);
  return result.data;
};

export const removeCluster = async ({ clusterId }: { clusterId: number }) => {
  await axios.delete(`${Config.apiBaseUrl}/clusters/${clusterId}`);
};

export const saveCluster = async ({
  name,
  kubeConfig,
  isSensitive,
}: {
  name: string;
  kubeConfig?: string;
  isSensitive: boolean;
}): Promise<Cluster> => {
  const result = await axios.post(`${Config.apiBaseUrl}/clusters`, {
    name: name,
    kubeConfig: kubeConfig,
    isSensitive: isSensitive,
  });
  return result.data;
};

export const editCluster = async ({
  clusterId,
  name,
  kubeConfig,
  isSensitive,
}: {
  clusterId: number;
  name: string;
  kubeConfig?: string;
  isSensitive: boolean;
}) => {
  const res = await axios.patch(`${Config.apiBaseUrl}/clusters/${clusterId}`, {
    name: name,
    kubeConfig: kubeConfig,
    isSensitive: isSensitive,
  });
  return res.data;
};
