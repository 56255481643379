import {
  createContext,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import Keycloak, {
  KeycloakLoginOptions,
  KeycloakLogoutOptions,
  KeycloakPromise,
} from "keycloak-js";

import Role from "../../../models/Role";
import { Spin } from "antd";
import getPopAppRole from "../../../Utils/domain/RoleDomain";
import setAuthorization from "./authorizationHeader";
import RedirectionPage from "../../../pages/RedirectionPage/RedirectionPage";

interface KeycloakAuthenticatorProps {
  children: ReactNode;
}

export interface KeycloakContext {
  logout(options?: KeycloakLogoutOptions): KeycloakPromise<void, void>;

  login(options?: KeycloakLoginOptions): KeycloakPromise<void, void>;

  tokenRef?: MutableRefObject<string | undefined>;
  roles: Role[];
}

const KeycloakConfig = {
  url: Config.keyCloakUrl,
  realm: Config.keyCloakRealm,
  clientId: Config.keyCloakClientId,
};

const keycloakInstance = new Keycloak(KeycloakConfig);

const initKeycloak = {
  logout: keycloakInstance.logout,
  login: keycloakInstance.login,
  tokenRef: undefined,
  roles: [],
};

export const KeycloakAuthenticatorContext =
  createContext<KeycloakContext>(initKeycloak);

const KeycloakAuthenticator = ({ children }: KeycloakAuthenticatorProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const roles = getPopAppRole(keycloakInstance.realmAccess?.roles ?? []);
  const tokenRef = useRef(keycloakInstance.token);
  const [secured, setSecured] = useState<boolean>(false);

  keycloakInstance.onTokenExpired = () => {
    keycloakInstance
      .updateToken(1)
      .then(() => {
        tokenRef.current = keycloakInstance.token;
        setAuthorization(keycloakInstance.token);
      })
      .catch(() => {
        keycloakInstance.login();
      });
  };

  keycloakInstance.onAuthLogout = () => keycloakInstance.login();

  useEffect(() => {
    if (window.location.pathname !== "/redirect") {
      setSecured(true);
      keycloakInstance
        .init({
          onLoad: "check-sso",
        })
        .then((authenticated) => {
          if (authenticated) {
            tokenRef.current = keycloakInstance.token;
            setAuthorization(keycloakInstance.token);
            setLoading(true);
          } else {
            keycloakInstance.login();
          }
        })
        .catch(() => {
          keycloakInstance.login();
        });
    } else {
      setLoading(true);
    }
  }, []);

  return (
    <>
      {secured ? (
        isLoading ? (
          <KeycloakAuthenticatorContext.Provider
            value={{
              ...initKeycloak,
              tokenRef,
              roles,
            }}
          >
            {children}
          </KeycloakAuthenticatorContext.Provider>
        ) : (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          >
            <Spin size="large" />
            <p>Loading...</p>
          </div>
        )
      ) : (
        <RedirectionPage />
      )}
    </>
  );
};

export default KeycloakAuthenticator;
