import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import KeycloakAuthenticator from "./components/Context/KeyCloakAuthenticator/KeyCloakAuthenticator";
import { Router } from "./components/Router";
import { SettingsContextProvider } from "./components/Context/SettingsContext";

export const App = () => {
  const queryClient = new QueryClient();

  return (
    <KeycloakAuthenticator>
      <React.StrictMode>
        <SettingsContextProvider>
          <QueryClientProvider client={queryClient}>
            <Router />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </SettingsContextProvider>
      </React.StrictMode>
    </KeycloakAuthenticator>
  );
};

export default App;
