import axios from "axios";
import { User } from "../models/User";
import { Page } from "../models/Page";

export const getUsers = async (
  page: number,
  pageSize: number
): Promise<Page<User>> => {
  const result = await axios.get<Page<User>>(
    `${Config.apiBaseUrl}/users?page=${page}&size=${pageSize}`
  );
  return result.data;
};

export const getUserById = async (userId: number): Promise<User> => {
  const result = await axios.get<User>(`${Config.apiBaseUrl}/users/${userId}`);
  return result.data;
};

export const createUser = () => axios.post(`${Config.apiBaseUrl}/users`, {});

export const updateUserRights = ({
  userId,
  userName,
  email,
  keycloakId,
  popApplicationIds,
  groupIds,
}: {
  userId: number;
  userName: string;
  email: string;
  keycloakId: string;
  popApplicationIds: number[];
  groupIds: number[];
}) => {
  return axios.patch(`${Config.apiBaseUrl}/users/${userId}`, {
    name: userName,
    email: email,
    keyclaokId: keycloakId,
    popApplicationIds: popApplicationIds,
    groupIds: groupIds,
  });
};

export const deleteUser = async (userId: number): Promise<User> => {
  const result = await axios.delete<User>(
    `${Config.apiBaseUrl}/users/${userId}`
  );
  return result.data;
};
