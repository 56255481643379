import { Button, Modal } from "antd";

interface DeleteModalProps {
  id: number | undefined;
  isModalDeleteOpen: boolean;
  setIsModalDeleteOpen: (open: boolean) => void;
  handleRemove: (id: number) => void;
}

const DeleteModal = ({
  id,
  isModalDeleteOpen,
  setIsModalDeleteOpen,
  handleRemove,
}: DeleteModalProps) => {
  return (
    <Modal
      onCancel={() => setIsModalDeleteOpen(false)}
      open={isModalDeleteOpen}
      centered
      footer={[
        <Button key="cancel" onClick={() => setIsModalDeleteOpen(false)}>
          Cancel
        </Button>,
        <Button
          key="Remove"
          type="primary"
          danger
          onClick={() => {
            setIsModalDeleteOpen(false);
            handleRemove(id!);
          }}
        >
          Remove
        </Button>,
      ]}
    >
      Are you sure you want to remove this ?
    </Modal>
  );
};

export default DeleteModal;
