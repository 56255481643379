import "./redirectionPage.scss";

const RedirectionPage = () => {
  return (
    <div className="shutdown-page">
      <h1>
        L'application à laquelle vous essayer d'accéder est actuellement éteinte
      </h1>
      <p>
        Nous sommes engagés dans la réduction de notre empreinte carbone et
        avons mis cette application hors service pour économiser de l'énergie.
        Elle est temporairement éteinte par Popapp pour des raisons écologiques.
      </p>
      <p>
        Si vous souhaitez allumer cette application, veuillez visiter{" "}
        <a href={`https://${Config.popappUrl}`}>{Config.popappUrl}</a> ou
        contacter l'administrateur de l'infrastructure
      </p>
      <p>Nous vous remercions de votre compréhension.</p>
    </div>
  );
};

export default RedirectionPage;
