import { Breadcrumb, Button, Card, ConfigProvider, Typography } from "antd";
import { useContext, useState } from "react";
import { ClusterContext } from "../HomePage/HomePage";

import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
  useHandleEditMutation,
  useHandleSaveMutation,
} from "../../mutations/useMutationCRDs";
import AddEditCrdForm from "../../components/CRDs/AddEditCrdForm";

const AddEditCrdsPage = ({ crdId }: { crdId: number | undefined }) => {
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState<string>();
  const [groupVersion, setGroupVersion] = useState<string>();
  const [pluralKind, setPluralKind] = useState<string>();
  const [pathReplicas, setPathReplicas] = useState<string>();
  const queryClient = useQueryClient();
  const { cluster } = useContext(ClusterContext);

  const handleSaveMutation = useHandleSaveMutation(queryClient);
  const handleEditMutation = useHandleEditMutation(queryClient);

  const handleSave = () => {
    handleSaveMutation.mutate({
      groupName: groupName!,
      groupVersion: groupVersion!,
      pluralKind: pluralKind!,
      clusterId: cluster.id!,
      pathReplicas: pathReplicas!,
    });
    navigate("/manage-crds");
  };

  const handleEdit = () => {
    handleEditMutation.mutate({
      crdId: crdId!,
      groupName: groupName!,
      groupVersion: groupVersion!,
      pluralKind: pluralKind!,
      clusterId: cluster.id!,
      pathReplicas: pathReplicas!,
    });
    navigate("/manage-crds");
  };

  const generateBreadcrumbItems = () => {
    const breadcrumbItems = [
      {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        title: <a onClick={() => navigate("/manage-crds")}>Manage CRDs</a>,
      },
    ];

    return breadcrumbItems;
  };

  const breadcrumbItems = generateBreadcrumbItems();

  return (
    <>
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <br />
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              fontSize: 20,
              controlHeight: 40,
            },
          },
        }}
      ></ConfigProvider>

      <br />

      <Card
        title={
          <Typography.Title level={1}>
            {crdId ? "Edit" : "Add"} CRD
          </Typography.Title>
        }
      >
        <AddEditCrdForm
          crdId={crdId}
          groupName={groupName}
          setGroupName={setGroupName}
          groupVersion={groupVersion}
          setGroupVersion={setGroupVersion}
          pluralKind={pluralKind}
          setPluralKind={setPluralKind}
          pathReplicas={pathReplicas}
          setPathReplicas={setPathReplicas}
        />
      </Card>
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Button onClick={() => navigate("/manage-crds")}>Cancel</Button>
        </div>
        <Button type="primary" onClick={crdId ? handleEdit : handleSave}>
          Save
        </Button>
      </div>
    </>
  );
};

export default AddEditCrdsPage;
