import { Layout } from "antd";
import React from "react";

const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      Made by Takima © 2023
    </Layout.Footer>
  );
};
export default Footer;
