import { Breadcrumb, Button, Card, ConfigProvider, Typography } from "antd";
import { useContext, useState } from "react";
import { ClusterContext } from "../HomePage/HomePage";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import FormMode from "../../models/Form";
import {
  useMutationEditGroup,
  useMutationSaveGroup,
} from "../../mutations/useMutationGroups";
import AddEditGroupForm from "../../components/Groups/AddEditGroupForm";
import { Application } from "../../models/Application";

const AddEditGroupPage = ({
  mode,
  groupId,
}: {
  mode: FormMode;
  groupId: number | undefined;
}) => {
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState("");
  const [applications, setApplications] = useState<Application[]>([]);
  const { cluster } = useContext(ClusterContext);
  const queryClient = useQueryClient();

  const saveGroupMutation = useMutationSaveGroup(queryClient);
  const editGroupMutation = useMutationEditGroup(queryClient);

  const handleSave = () => {
    saveGroupMutation.mutate({
      groupName: groupName!,
      popApplicationIds: applications.map((application) => application.id),
      clusterId: cluster.id!,
    });
    navigate("/manage-groups");
  };

  const handleEdit = () => {
    editGroupMutation.mutate({
      groupId: groupId!,
      groupName: groupName!,
      popApplicationIds: applications.map((application) => application.id),
      clusterId: cluster.id!,
    });
    navigate("/manage-groups");
  };

  const generateBreadcrumbItems = () => {
    const breadcrumbItems = [
      {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        title: <a onClick={() => navigate("/manage-groups")}>Manage Groups</a>,
      },
    ];

    return breadcrumbItems;
  };

  const breadcrumbItems = generateBreadcrumbItems();

  return (
    <>
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <br />
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              fontSize: 20,
              controlHeight: 40,
            },
          },
        }}
      ></ConfigProvider>

      <br />

      <Card
        title={
          <Typography.Title level={1}>
            {mode === FormMode.EDIT ? "Edit" : "Add"} Group
          </Typography.Title>
        }
      >
        <AddEditGroupForm
          groupId={groupId}
          mode={mode}
          groupName={groupName}
          setGroupName={setGroupName}
          selectedApplications={applications}
          setSelectedApplications={setApplications}
        />
      </Card>
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Button onClick={() => navigate("/manage-groups")}>Cancel</Button>
        </div>
        <Button
          type="primary"
          onClick={mode === FormMode.EDIT ? handleEdit : handleSave}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AddEditGroupPage;
