import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import AgendaPage from "../pages/AgendaPage/AgendaPage";
import ApplicationDetailsPage from "../pages/ApplicationsPage/ApplicationDetailsPage";
import ApplicationsPage from "../pages/ApplicationsPage/ApplicationsPage";
import NotFound from "../pages/ErrorPages/NotFound";
import GroupPage from "../pages/GroupsPage/GroupsPage";
import HomePage from "../pages/HomePage/HomePage";
import ManageAppsPage from "../pages/ManageAppsPage/ManageAppsPage";
import ListClusterPage from "../pages/ManageClustersPage/ManageClusterPage";
import ListCRDsPage from "../pages/ManageCrdsPage/ManageCrdsPage";
import ManageGroupsPage from "../pages/ManageGroupsPage/ManageGroupsPage";
import { useContext } from "react";
import { KeycloakAuthenticatorContext } from "./Context/KeyCloakAuthenticator/KeyCloakAuthenticator";
import Role from "../models/Role";
import ManageUserPage from "../pages/ManageUserPage/ManageUserPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import getNotification, {
  NotificationType,
} from "./Applications/ThemeNotification";
import AddEditAppsPage from "../pages/ManageAppsPage/AddEditAppsPage";
import FormMode from "../models/Form";
import AddEditCrdsPage from "../pages/ManageCrdsPage/AddEditCrdsPage";
import AddEditGroupPage from "../pages/ManageGroupsPage/AddEditGroupPage";
import AddEditClusterPage from "../pages/ManageClustersPage/AddEditClusterPage";
import EditUserPage from "../pages/ManageUserPage/EditUserPage";

const ApplicationDetailsWrapper = () => {
  const { appId, groupId } = useParams();
  if (groupId) {
    return (
      <ApplicationDetailsPage
        mode={"group"}
        appId={Number(appId)}
        groupId={Number(groupId)}
      />
    );
  } else {
    return (
      <ApplicationDetailsPage mode={"applications"} appId={Number(appId)} />
    );
  }
};

const GroupDetailsWrapper = () => {
  const { groupId } = useParams();
  return <ApplicationsPage mode={"group"} groupId={Number(groupId)} />;
};

const ProtectedRoute: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { roles } = useContext(KeycloakAuthenticatorContext);

  if (!roles.includes(Role.ADMIN)) {
    getNotification({
      message: "Acess forbidden",
      type: NotificationType.ERROR,
    });
    return <Navigate to="/applications" />;
  }

  return <>{children}</>;
};

const AddEditAppsPageWrapper = () => {
  const { appName } = useParams();
  const location = useLocation();

  const appId = location.state?.appId;

  return (
    <AddEditAppsPage appId={appId} appName={appName} mode={FormMode.EDIT} />
  );
};

const AddEditCrdsPageWrapper = () => {
  const { crdId } = useParams();

  return <AddEditCrdsPage crdId={Number(crdId)} />;
};

const AddEditGroupPageWrapper = () => {
  const { groupId } = useParams();

  return <AddEditGroupPage groupId={Number(groupId)} mode={FormMode.EDIT} />;
};

const AddEditClusterPageWrapper = () => {
  const { clusterId } = useParams();

  return (
    <AddEditClusterPage clusterId={Number(clusterId)} mode={FormMode.EDIT} />
  );
};

const EditUserPageWrapper = () => {
  const { userId } = useParams();

  return <EditUserPage userId={Number(userId)} />;
};

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />}>
          <Route index element={<ApplicationsPage mode={"applications"} />} />
          <Route
            path="applications"
            element={<ApplicationsPage mode={"applications"} />}
          />
          <Route path="agenda" element={<AgendaPage />} />
          <Route
            path="manage-app"
            element={
              <ProtectedRoute>
                <ManageAppsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="manage-clusters"
            element={
              <ProtectedRoute>
                <ListClusterPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="manage-groups"
            element={
              <ProtectedRoute>
                <ManageGroupsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="manage-users"
            element={
              <ProtectedRoute>
                <ManageUserPage />
              </ProtectedRoute>
            }
          />
          <Route path="groups" element={<GroupPage />} />
          <Route
            path="manage-crds"
            element={
              <ProtectedRoute>
                <ListCRDsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="applications/application-details/:appId"
            element={<ApplicationDetailsWrapper />}
          />
          <Route
            path="groups/application-details/:appId"
            element={<ApplicationDetailsWrapper />}
          />
          <Route
            path="application-details/group/:groupId/application/:appId"
            element={<ApplicationDetailsWrapper />}
          />

          <Route
            path="group-details/:groupId"
            element={<GroupDetailsWrapper />}
          />

          <Route
            path="add-app"
            element={
              <AddEditAppsPage
                appId={undefined}
                appName={undefined}
                mode={FormMode.ADD}
              />
            }
          />
          <Route
            path="edit-app/:appName"
            element={<AddEditAppsPageWrapper />}
          />
          <Route
            path="add-crd"
            element={<AddEditCrdsPage crdId={undefined} />}
          />
          <Route path="edit-crd/:crdId" element={<AddEditCrdsPageWrapper />} />
          <Route
            path="add-group"
            element={
              <AddEditGroupPage mode={FormMode.ADD} groupId={undefined} />
            }
          />
          <Route
            path="edit-group/:groupId"
            element={<AddEditGroupPageWrapper />}
          />
          <Route
            path="add-cluster"
            element={
              <AddEditClusterPage mode={FormMode.ADD} clusterId={undefined} />
            }
          />
          <Route
            path="edit-cluster/:clusterId"
            element={<AddEditClusterPageWrapper />}
          />
          <Route path="edit-user/:userId" element={<EditUserPageWrapper />} />

          <Route path="settings" element={<SettingsPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
