export const getTimeElapsed = (lastRefreshTime: Date) => {
  const now = new Date();
  const timeElapsed = Math.floor(
    (now.getTime() - lastRefreshTime.getTime()) / (1000 * 60)
  );
  const hoursElapsed = Math.floor(timeElapsed / 60);
  const daysElapsed = Math.floor(hoursElapsed / 24);

  switch (true) {
    case timeElapsed < 1:
      return "now";
    case timeElapsed < 60:
      return `${timeElapsed} minute(s) ago`;
    case hoursElapsed < 24:
      return `${hoursElapsed} hour(s) ago`;
    default:
      return `${daysElapsed} day(s) ago`;
  }
};
