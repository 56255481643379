import { QueryClient, useMutation } from "@tanstack/react-query";
import {
  editCluster,
  removeCluster,
  saveCluster,
} from "../services/listCluster";
import { syncBackendWithDB } from "../services/listApplicationsApi";
import getNotification, {
  NotificationType,
} from "../components/Applications/ThemeNotification";
import ReactQueryKeys from "./ReactQueryKeys";

export const useMutationAddCluster = (queryClient: QueryClient) =>
  useMutation(saveCluster, {
    onError: () =>
      getNotification({
        message: "Adding application failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.CLUSTERS]).then();
      getNotification({
        message: "Cluster added successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationUpdateCluster = (queryClient: QueryClient) =>
  useMutation(editCluster, {
    onError: () =>
      getNotification({
        message: "Updating cluster failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      getNotification({
        message: "Cluster updated successfully",
        type: NotificationType.SUCCESS,
      });
      queryClient.invalidateQueries([ReactQueryKeys.CLUSTERS]);
    },
  });
export const useMutationCluster = (queryClient: QueryClient) =>
  useMutation(removeCluster, {
    onError: () =>
      getNotification({
        message: "Removing cluster failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.CLUSTERS]).then();
      getNotification({
        message: "Cluster removed successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useSync = (
  queryClient: QueryClient,
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date>>
) =>
  useMutation(syncBackendWithDB, {
    onError: () =>
      getNotification({
        message: "Application synchronisation failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([
        ReactQueryKeys.CLUSTERS,
        ReactQueryKeys.APPLICATIONS,
      ]);
      setLastRefreshTime(new Date());
      getNotification({
        message: "Cluster refreshed successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });
