import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import { getCRDsInCluster } from "../../services/manageCRDsAPI";
import { ClusterContext } from "../HomePage/HomePage";
import { ColumnsType } from "antd/es/table";
import { Button, FloatButton, Table, Tooltip } from "antd";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import Loading from "../../components/LoadingAndError/Loading";
import Error from "../../components/LoadingAndError/Error";
import { CRD } from "../../models/CRD";
import {
  useHandleRemoveCRD,
  useHandleSyncCRD,
} from "../../mutations/useMutationCRDs";
import DeleteModal from "../../components/Modal/DeleteModal";
import usePagination from "../../hooks/usePagination";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import DangerZone from "../../components/DangerZone/DangerZone";
import { useNavigate } from "react-router-dom";

const ListCRDsPage = () => {
  const { cluster } = React.useContext(ClusterContext);
  const { setLastRefreshTime, LastUpdateTimeComponent } = useLastUpdateTime();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const CRDs = usePagination(
    (page, pageSize) =>
      getCRDsInCluster({
        page: page,
        pageSize: pageSize,
        clusterId: cluster.id!,
      }),

    ReactQueryKeys.CRD_LIST,
    cluster.id
  );

  const onChangePage = (newPage: number) => {
    CRDs.setPage(newPage);
  };

  const handleSyncMutation = useHandleSyncCRD(queryClient, setLastRefreshTime);

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectedCrdId, setSelectedCrdId] = useState<number | undefined>(
    undefined
  );

  const handleRemoveCRDMutation = useHandleRemoveCRD(queryClient);

  const handleRemoveCRD = async (crdID: number) => {
    handleRemoveCRDMutation.mutate(crdID);
  };

  const handleSync = () => {
    handleSyncMutation.mutate();
    setLastRefreshTime(new Date());
  };

  const columns: ColumnsType<CRD> = [
    {
      title: "CRD Name",
      dataIndex: "groupName",
      key: "name",
    },

    {
      title: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip title="Synchronize">
            <Button icon={<SyncOutlined />} onClick={handleSync} />
          </Tooltip>
        </div>
      ),
      key: "actions",
      render: (text: string, record: CRD) => (
        <>
          <Button
            onClick={() => {
              setSelectedCrdId(record.id);
              navigate(`/edit-crd/${record.id}`);
            }}
          >
            Edit
          </Button>
          <span style={{ margin: "0 10px" }}></span>
          <Button
            type="primary"
            onClick={() => {
              setSelectedCrdId(record.id);
              setIsModalDeleteOpen(true);
            }}
          >
            Remove
          </Button>
        </>
      ),
      align: "right" as const,
    },
  ];
  return CRDs.isLoading ? (
    <Loading />
  ) : CRDs.isError ? (
    <Error height="80vh" />
  ) : (
    <>
      <LastUpdateTimeComponent />
      <DangerZone isSensitive={cluster.sensitive} />
      <Table
        columns={columns}
        dataSource={CRDs.items}
        rowKey="id"
        pagination={{
          total: CRDs.total,
          current: CRDs.page,
          pageSize: CRDs.pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onChangePage,
        }}
      />
      <FloatButton
        shape="circle"
        tooltip="Add CRD"
        type="primary"
        style={{ right: 94, width: 60, height: 60 }}
        icon={<PlusOutlined />}
        onClick={() => {
          setSelectedCrdId(undefined);
          navigate("/add-crd");
        }}
      />
      <DeleteModal
        id={selectedCrdId}
        isModalDeleteOpen={isModalDeleteOpen}
        setIsModalDeleteOpen={setIsModalDeleteOpen}
        handleRemove={handleRemoveCRD}
      />
    </>
  );
};

export default ListCRDsPage;
