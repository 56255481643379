import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { Tag } from "antd";

const Status = ({ isActive }: { isActive: boolean }) => {
  return (
    <Tag
      icon={isActive ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
      color={isActive ? "success" : "error"}
    >
      {isActive ? "On" : "Off"}
    </Tag>
  );
};

export default Status;
