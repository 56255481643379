import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import { Button, Table, Tooltip } from "antd";
import Loading from "../../components/LoadingAndError/Loading";
import { ColumnsType } from "antd/es/table";
import { User } from "../../models/User";
import { SyncOutlined } from "@ant-design/icons";
import { useSync } from "../../mutations/useMutationCluster";
import { getUsers } from "../../services/manageUserApi";
import Error from "../../components/LoadingAndError/Error";
import DeleteModal from "../../components/Modal/DeleteModal";
import { useMutationDeleteUser } from "../../mutations/useMutationUser";
import usePagination from "../../hooks/usePagination";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import { useNavigate } from "react-router-dom";

const ManageUserPage = () => {
  const queryClient = useQueryClient();
  const { setLastRefreshTime, LastUpdateTimeComponent } = useLastUpdateTime();
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(
    undefined
  );
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const navigate = useNavigate();

  const users = usePagination<User>(getUsers, ReactQueryKeys.USERS);

  const onChangePage = (newPage: number, newPageSize: number) => {
    users.setPage(newPage);
    users.setPageSize(newPageSize);
  };

  const handleSyncMutation = useSync(queryClient, setLastRefreshTime);

  const handleSync = () => {
    handleSyncMutation.mutate();
    setLastRefreshTime(new Date());
  };

  const handleDeleteUserMutation = useMutationDeleteUser(queryClient);

  const handleRemoveUser = (selectedUserId: number) => {
    handleDeleteUserMutation.mutate(selectedUserId);
  };

  const columns: ColumnsType<User> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip title="Synchronize">
            <Button icon={<SyncOutlined />} onClick={handleSync} />
          </Tooltip>
        </div>
      ),
      render: (_: unknown, record: any) => (
        <>
          <Button
            onClick={() => {
              setSelectedUserId(record.id);
              navigate(`/edit-user/${record.id}`);
            }}
          >
            Edit
          </Button>
          <span style={{ margin: "0 10px" }}></span>
          <Button
            type="primary"
            onClick={() => {
              setSelectedUserId(record.id);
              setIsModalDeleteOpen(true);
            }}
          >
            Remove
          </Button>
        </>
      ),
      align: "right" as const,
    },
  ];
  return users.isLoading ? (
    <Loading />
  ) : users.isError ? (
    <Error height="80vh" />
  ) : (
    <>
      <LastUpdateTimeComponent />
      <Table
        columns={columns}
        dataSource={users.items}
        rowKey="id"
        pagination={{
          total: users.total,
          current: users.page,
          pageSize: users.pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onChangePage,
        }}
      />
      <DeleteModal
        id={selectedUserId}
        isModalDeleteOpen={isModalDeleteOpen}
        setIsModalDeleteOpen={setIsModalDeleteOpen}
        handleRemove={handleRemoveUser}
      />
    </>
  );
};

export default ManageUserPage;
