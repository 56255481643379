import React, { useContext, useState } from "react";
import { Button, FloatButton, Table, Tooltip } from "antd";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { fetchClusters } from "../../services/listCluster";
import { Cluster } from "../../models/Cluster";
import { ColumnsType } from "antd/es/table";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import { useQueryClient } from "@tanstack/react-query";
import Loading from "../../components/LoadingAndError/Loading";
import Error from "../../components/LoadingAndError/Error";
import {
  useMutationCluster,
  useSync,
} from "../../mutations/useMutationCluster";
import DeleteModal from "../../components/Modal/DeleteModal";

import usePagination from "../../hooks/usePagination";

import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import { useNavigate } from "react-router-dom";
import DangerZone from "../../components/DangerZone/DangerZone";
import { ClusterContext } from "../HomePage/HomePage";

const ManageClusterPage = () => {
  const { setLastRefreshTime, LastUpdateTimeComponent } = useLastUpdateTime();
  const queryClient = useQueryClient();
  const { cluster } = useContext(ClusterContext);
  const clusters = usePagination(
    (page, pageSize) => fetchClusters(page, pageSize),
    ReactQueryKeys.CLUSTERS
  );

  const onChangePage = (newPage: number) => {
    clusters.setPage(newPage);
  };

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectedClusterId, setSelectedClusterId] = useState<
    number | undefined
  >(undefined);
  const navigate = useNavigate();

  const deleteCluster = useMutationCluster(queryClient);

  const handleRemoveCluster = (clusterId: number) => {
    deleteCluster.mutate({
      clusterId,
    });
  };

  const syncClusterMutation = useSync(queryClient, setLastRefreshTime);
  const handleSync = () => {
    syncClusterMutation.mutate();
    setLastRefreshTime(new Date());
  };

  const columns: ColumnsType<Cluster> = [
    {
      title: "Cluster Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip title="Synchronize">
            <Button icon={<SyncOutlined />} onClick={handleSync} />
          </Tooltip>
        </div>
      ),
      render: (text: string, record: Cluster) => (
        <>
          <Button
            onClick={() => {
              setSelectedClusterId(record.id);
              navigate(`/edit-cluster/${record.id}`);
            }}
          >
            Edit
          </Button>
          <span style={{ margin: "0 10px" }}></span>
          <Button
            type="primary"
            onClick={() => {
              setSelectedClusterId(record.id);
              setIsModalDeleteOpen(true);
            }}
          >
            Remove
          </Button>
        </>
      ),
      align: "right" as const,
    },
  ];
  return clusters.isLoading ? (
    <Loading />
  ) : clusters.isError ? (
    <Error height="80vh" />
  ) : (
    <>
      <LastUpdateTimeComponent />
      <DangerZone isSensitive={cluster.sensitive} />
      <Table
        columns={columns}
        dataSource={clusters.items}
        rowKey="id"
        pagination={{
          total: clusters.total,
          current: clusters.page,
          pageSize: clusters.pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onChangePage,
        }}
      />
      <FloatButton
        shape="circle"
        tooltip="Add Cluster"
        type="primary"
        style={{ right: 94, width: 60, height: 60 }}
        icon={<PlusOutlined />}
        onClick={() => {
          navigate("/add-cluster");
          setSelectedClusterId(undefined);
        }}
      />
      <DeleteModal
        id={selectedClusterId}
        isModalDeleteOpen={isModalDeleteOpen}
        setIsModalDeleteOpen={setIsModalDeleteOpen}
        handleRemove={handleRemoveCluster}
      />
    </>
  );
};

export default ManageClusterPage;
