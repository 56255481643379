import { Breadcrumb, Button, Card, ConfigProvider, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import FormMode from "../../models/Form";
import {
  useMutationAddCluster,
  useMutationUpdateCluster,
} from "../../mutations/useMutationCluster";
import getNotification, {
  NotificationType,
} from "../../components/Applications/ThemeNotification";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import AddEditClusterForm from "../../components/Clusters/AddEditClusterForm";

const AddEditClusterPage = ({
  mode,
  clusterId,
}: {
  mode: FormMode;
  clusterId: number | undefined;
}) => {
  const navigate = useNavigate();
  const [clusterName, setClusterName] = useState("");
  const [kubeConfig, setKubeConfig] = useState<File | null>(null);
  const [isSensitive, setIsSensitive] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const addClusterMutation = useMutationAddCluster(queryClient);
  const updateClusterMutation = useMutationUpdateCluster(queryClient);

  const reset = () => {
    setClusterName("");
    setKubeConfig(null);
    setIsSensitive(false);
  };

  const handleSave = async () => {
    if (kubeConfig) {
      const reader = new FileReader();
      reader.readAsDataURL(kubeConfig);
      reader.onload = async () => {
        const base64Data = reader.result?.toString().split(",")[1];

        if (!base64Data) {
          getNotification({
            message: "Failed to read kube config file.",
            type: NotificationType.ERROR,
          });
          return;
        }
        if (!base64Data) {
          getNotification({
            message: "Failed to read kube config file.",
            type: NotificationType.ERROR,
          });
          reset();
          return;
        }

        if (mode === FormMode.ADD) {
          addClusterMutation.mutate({
            name: clusterName,
            kubeConfig: base64Data,
            isSensitive,
          });
        } else if (mode === FormMode.EDIT && clusterId) {
          updateClusterMutation.mutate({
            clusterId,
            name: clusterName,
            kubeConfig: base64Data,
            isSensitive,
          });
        }
        reset();
        navigate("/manage-clusters");
        queryClient.invalidateQueries([ReactQueryKeys.CLUSTERS]);
      };
    }
    if (mode === FormMode.EDIT && clusterId && !kubeConfig) {
      updateClusterMutation.mutate({
        clusterId,
        name: clusterName,
        isSensitive,
      });
      navigate("/manage-clusters");
      queryClient.invalidateQueries([ReactQueryKeys.CLUSTERS]);
    }
  };

  const handleUpload = (file: File) => {
    setKubeConfig(file);
    return false;
  };

  const generateBreadcrumbItems = () => {
    const breadcrumbItems = [
      {
        title: (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={() => navigate("/manage-clusters")}>Manage Clusters</a>
        ),
      },
    ];

    return breadcrumbItems;
  };

  const breadcrumbItems = generateBreadcrumbItems();

  return (
    <>
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <br />
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              fontSize: 20,
              controlHeight: 40,
            },
          },
        }}
      ></ConfigProvider>

      <br />

      <Card
        title={
          <Typography.Title level={1}>
            {mode === FormMode.EDIT ? "Edit" : "Add"} Group
          </Typography.Title>
        }
      >
        <AddEditClusterForm
          clusterId={clusterId}
          mode={mode}
          isSensitive={isSensitive}
          setIsSensitive={setIsSensitive}
          clusterName={clusterName}
          setClusterName={setClusterName}
          handleUpload={handleUpload}
        />
      </Card>
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Button onClick={() => navigate("/manage-clusters")}>Cancel</Button>
        </div>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </>
  );
};

export default AddEditClusterPage;
