import { QueryClient, useMutation } from "@tanstack/react-query";
import {
  createSchedule,
  deleteSchedules,
  updateSchedules,
} from "../services/scheduleApi";
import ReactQueryKeys from "./ReactQueryKeys";
import getNotification, {
  NotificationType,
} from "../components/Applications/ThemeNotification";

export const useMutationDeleteSchedule = (
  queryClient: QueryClient,
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date>>
) =>
  useMutation(deleteSchedules, {
    onError: () =>
      getNotification({
        message: "Removing schedule failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APP_SCHEDULES]);
      setLastRefreshTime(new Date());
      getNotification({
        message: "Schedule removed successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationCreateSchedule = (
  queryClient: QueryClient,
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date>>
) =>
  useMutation(createSchedule, {
    onError: () =>
      getNotification({
        message: "Failed to create this schedule",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APP_SCHEDULES]);
      queryClient.invalidateQueries([ReactQueryKeys.APP_SCHEDULE_INFO]);
      setLastRefreshTime(new Date());
      getNotification({
        message: "Schedule created successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationUpdateSchedule = (
  queryClient: QueryClient,
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date>>
) =>
  useMutation(updateSchedules, {
    onError: (error: any) => {
      if (error.response.status === 409) {
        getNotification({
          message:
            "A scheduling conflict occurred. Please choose a different time.",
          type: NotificationType.ERROR,
        });
      } else {
        getNotification({
          message: "An error occurred. Please try again.",
          type: NotificationType.ERROR,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APP_SCHEDULES]);
      queryClient.invalidateQueries([ReactQueryKeys.APP_SCHEDULE_INFO]);
      setLastRefreshTime(new Date());
      getNotification({
        message: "Schedule updated successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });
