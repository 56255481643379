import { useContext, useState } from "react";
import { Breadcrumb, Button, Space, Table, Tooltip } from "antd";
import { ClusterContext } from "../HomePage/HomePage";
import { useNavigate } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { useQueryClient } from "@tanstack/react-query";
import Loading from "../../components/LoadingAndError/Loading";
import Error from "../../components/LoadingAndError/Error";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import { useMutationSyncGroup } from "../../mutations/useMutationGroup";
import { fetchGroups } from "../../services/listGroupApi";
import { Group } from "../../models/Group";
import { useChangeStateGroupMutation } from "../../mutations/useMutationGroups";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import usePagination from "../../hooks/usePagination";
import WarningModal from "../../components/Modal/WarningModal";
import DangerZone from "../../components/DangerZone/DangerZone";
import Status from "../../components/Applications/Status";

const GroupsPage = () => {
  const { cluster } = useContext(ClusterContext);
  const queryClient = useQueryClient();
  const [switching, setSwitching] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number>(0);
  const [clickedLineState, setClikedLineState] = useState(false);
  const navigate = useNavigate();

  const groups = usePagination(
    (page, pageSize) =>
      fetchGroups({
        page: page,
        pageSize: pageSize,
        isAdminPage: false,
        clusterId: cluster.id!,
      }),
    ReactQueryKeys.GROUPS
  );

  const onChangePage = (newPage: number, newPageSize: number) => {
    groups.setPage(newPage);
    groups.setPageSize(newPageSize);
  };

  const { setLastRefreshTime, LastUpdateTimeComponent } = useLastUpdateTime();

  const handleGroupSwitchMutation = useChangeStateGroupMutation(
    queryClient,
    setSwitching
  );

  const handleGroupSwitch = (isActive: boolean, groupId: number) => {
    handleGroupSwitchMutation.mutate({
      isActive: isActive,
      groupId: groupId,
    });
  };

  const handleSyncMutation = useMutationSyncGroup(queryClient);

  const handleSync = () => {
    handleSyncMutation.mutate();
    setLastRefreshTime(new Date());
  };

  const handleRowClick = (record: Group) => ({
    onClick: () => {
      navigate(`/group-details/${record.id}`, {
        state: { fromGroupsPage: true },
      });
    },
    style: { cursor: "pointer" },
  });

  const columns: ColumnsType<Group> = [
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: Group) => text,
    },
    {
      title: "State",
      dataIndex: "isActive",
      key: "state",
      render: (text: string, record: Group) => {
        const state = record.popApplications.some((r) =>
          r.resources.some((rs) => rs.currentReplicasAmount > 0)
        );
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Status isActive={state} />
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            Action
            <Tooltip title="Synchronize">
              <Button icon={<SyncOutlined />} onClick={handleSync} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "isActive",
      key: "isActive",
      render: (text: string, record: Group) => {
        const state = record.popApplications.some((r) =>
          r.resources.some((rs) => rs.currentReplicasAmount > 0)
        );
        return (
          <Space>
            <Button
              type="default"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                if (!switching) {
                  setClikedLineState(!state);
                  setSelectedGroupId(record.id);
                  setIsWarningModalOpen(true);
                }
              }}
              disabled={switching}
            >
              {!state ? "On" : "Off"}
            </Button>
          </Space>
        );
      },
    },
  ];

  const generateBreadcrumbItems = () => {
    const breadcrumbItems = [
      {
        title: "Groups",
      },
    ];

    return breadcrumbItems;
  };

  const breadcrumbItems = generateBreadcrumbItems();
  return groups.isLoading ? (
    <Loading />
  ) : groups.isError ? (
    <Error height="80vh" />
  ) : (
    <>
      <Breadcrumb>
        {breadcrumbItems.map((item) => (
          <Breadcrumb.Item key={item.title}>{item.title}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <LastUpdateTimeComponent />
      <DangerZone isSensitive={cluster.sensitive} />
      <Table
        className="text"
        columns={columns}
        dataSource={groups.items}
        rowKey="id"
        onRow={handleRowClick}
        loading={groups.isLoading}
        pagination={{
          total: groups.total,
          current: groups.page,
          pageSize: groups.pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onChangePage,
        }}
      />
      <WarningModal
        primaryId={selectedGroupId}
        isActive={clickedLineState}
        name={groups.items?.find((item) => item.id === selectedGroupId)?.name}
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        handleSwitch={handleGroupSwitch}
      />
    </>
  );
};

export default GroupsPage;
