import { Empty } from "antd";

interface ErrorProps {
  height: string | number | undefined;
}

const Error = ({ height }: ErrorProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height,
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );
};

export default Error;
