import { Button } from "antd";
import React from "react";

interface ButtonModalProps {
  handleCancel: () => void;
  handleOk: () => void;
}

const ButtonModal = ({ handleCancel, handleOk }: ButtonModalProps) => {
  return (
    <div style={{ textAlign: "right", marginTop: "16px" }}>
      <Button onClick={handleCancel} style={{ marginRight: "8px" }}>
        Cancel
      </Button>
      <Button type="primary" onClick={handleOk}>
        Save
      </Button>
    </div>
  );
};

export default ButtonModal;
