import { useEffect } from "react";
import { Input } from "antd";
import { getCRD } from "../../services/manageCRDsAPI";
import { CRD } from "../../models/CRD";

interface CrdsFormProps {
  crdId?: number;
  groupName: string | undefined;
  setGroupName: React.Dispatch<React.SetStateAction<string | undefined>>;
  groupVersion: string | undefined;
  setGroupVersion: React.Dispatch<React.SetStateAction<string | undefined>>;
  pluralKind: string | undefined;
  setPluralKind: React.Dispatch<React.SetStateAction<string | undefined>>;
  pathReplicas: string | undefined;
  setPathReplicas: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const AddEditCrdForm = ({
  crdId,
  groupName,
  setGroupName,
  groupVersion,
  setGroupVersion,
  pluralKind,
  setPluralKind,
  pathReplicas,
  setPathReplicas,
}: CrdsFormProps) => {
  useEffect(() => {
    if (crdId) {
      getCRD(crdId!).then((data: CRD) => {
        setGroupName(data.groupName);
        setGroupVersion(data.groupVersion);
        setPluralKind(data.pluralKind);
        setPathReplicas(data.pathReplicas);
      });
    }
    // eslint-disable-next-line
  }, [crdId]);
  return (
    <>
      <Input
        placeholder={crdId === undefined ? "Enter Group Name" : groupName}
        defaultValue={groupName}
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        style={{ marginBottom: "10px" }}
      />
      <Input
        placeholder={crdId === undefined ? "Enter Group Version" : groupVersion}
        defaultValue={groupVersion}
        value={groupVersion}
        onChange={(e) => setGroupVersion(e.target.value)}
        style={{ marginBottom: "10px" }}
      />
      <Input
        placeholder={crdId === undefined ? "Enter Plural Kind" : pluralKind}
        defaultValue={pluralKind}
        value={pluralKind}
        onChange={(e) => setPluralKind(e.target.value)}
        style={{ marginBottom: "10px" }}
      />
      <Input
        placeholder={crdId === undefined ? "Enter Path Replicas" : pathReplicas}
        defaultValue={pathReplicas}
        value={pathReplicas}
        onChange={(e) => setPathReplicas(e.target.value)}
        style={{ marginBottom: "10px" }}
      />
    </>
  );
};

export default AddEditCrdForm;
