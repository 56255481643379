import { Button, Modal } from "antd";

interface WarningModalProps {
  primaryId: number | undefined;
  secondaryId?: number | undefined;
  isActive: boolean;
  name: string | undefined;
  isWarningModalOpen: boolean;
  setIsWarningModalOpen: (open: boolean) => void;
  handleSwitch: (isActive: boolean, appId: number, groupId?: number) => void;
}

const WarningModal = ({
  primaryId,
  secondaryId,
  isActive,
  name,
  isWarningModalOpen,
  setIsWarningModalOpen,
  handleSwitch,
}: WarningModalProps) => {
  const darkMode = localStorage.getItem("darkMode") === "true";
  return (
    <Modal
      onCancel={() => setIsWarningModalOpen(false)}
      open={isWarningModalOpen}
      centered
      footer={[
        <Button key="cancel" onClick={() => setIsWarningModalOpen(false)}>
          Cancel
        </Button>,
        <Button
          key="state"
          type="primary"
          onClick={() => {
            setIsWarningModalOpen(false);
            handleSwitch(isActive, primaryId!, secondaryId);
          }}
        >
          {isActive ? "On" : "Off"}
        </Button>,
      ]}
    >
      <div>
        <span>
          Are you sure you want to turn {isActive ? "on " : "off "}
          <code
            style={{
              borderRadius: "5px",
              padding: "2px",
              backgroundColor: darkMode ? "#333" : "#f0f0f0",
              color: darkMode ? "#fff" : "#333",
            }}
          >
            {name}
          </code>{" "}
          ?
        </span>
      </div>
    </Modal>
  );
};

export default WarningModal;
