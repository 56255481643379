import React, { useContext } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { fetchAllSchedules } from "../../services/scheduleApi";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import "@fullcalendar/google-calendar";
import { EventClickArg } from "@fullcalendar/core";
import { useNavigate } from "react-router-dom";
import { ClusterContext } from "../HomePage/HomePage";
import { useQuery } from "@tanstack/react-query";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";

const AgendaPage = () => {
  const { cluster } = useContext(ClusterContext);
  const { LastUpdateTimeComponent } = useLastUpdateTime();
  const navigate = useNavigate();

  const { data: schedules } = useQuery({
    queryKey: [ReactQueryKeys.SCHEDULES, cluster.id!],
    queryFn: () => fetchAllSchedules(cluster.id!),
  });

  const formatSchedulesForCalendar = () => {
    return schedules?.map((schedule) => ({
      id: schedule.id.toString(),
      title: schedule.popApplicationResponseDto.name,
      popApplicationId: schedule.popApplicationResponseDto.id,
      start: schedule.startDate,
      end: schedule.endDate,
      allDay: true,
    }));
  };

  const handleEventClick = (eventClickInfo: EventClickArg) => {
    const eventId = eventClickInfo.event._def.extendedProps.popApplicationId;
    navigate(`/applications/application-details/${eventId}`);
  };

  return (
    <>
      <LastUpdateTimeComponent />
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={formatSchedulesForCalendar()}
        eventClick={handleEventClick}
      />
    </>
  );
};

export default AgendaPage;
