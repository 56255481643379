import { QueryClient, useMutation } from "@tanstack/react-query";
import {
  createUser,
  deleteUser,
  getUserById,
  updateUserRights,
} from "../services/manageUserApi";
import getNotification, {
  NotificationType,
} from "../components/Applications/ThemeNotification";
import ReactQueryKeys from "./ReactQueryKeys";

export const useMutationGetUserById = () =>
  useMutation(getUserById, {
    onError: () =>
      getNotification({
        message: "This user doesn't exist",
        type: NotificationType.ERROR,
      }),
  });

export const useMutationCreateUser = () =>
  useMutation(createUser, {
    onError: () =>
      getNotification({
        message: "User creation failed",
        type: NotificationType.ERROR,
      }),
  });

export const useMutationUpdateApplicationUser = (queryClient: QueryClient) =>
  useMutation(updateUserRights, {
    onError: () =>
      getNotification({
        message: "Failed to apply updates",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.APPLICATIONS]).then(() =>
        getNotification({
          message: "The user's rights have been updated successfully",
          type: NotificationType.SUCCESS,
        })
      );
    },
  });

export const useMutationDeleteUser = (queryClient: QueryClient) =>
  useMutation(deleteUser, {
    onError: () =>
      getNotification({
        message: "Failed to delete this user",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.USERS]);
      getNotification({
        message: "User deleted",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationUpdateUserRights = (queryClient: QueryClient) =>
  useMutation(updateUserRights, {
    onError: () =>
      getNotification({
        message: "Authorization's update failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      getNotification({
        message: "Authorization successfully updated",
        type: NotificationType.SUCCESS,
      });
    },
  });
