import { SyncOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Breadcrumb, Button, Empty, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import Error from "../../components/LoadingAndError/Error";
import Loading from "../../components/LoadingAndError/Loading";
import { Application } from "../../models/Application";
import {
  useMutationHandleAppSwitch,
  useMutationSyncApp,
} from "../../mutations/useMutationApplication";
import { getApplications } from "../../services/listApplicationsApi";
import { ClusterContext } from "../HomePage/HomePage";
import { getGroupWithId } from "../../services/listGroupApi";
import usePagination from "../../hooks/usePagination";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import WarningModal from "../../components/Modal/WarningModal";
import { KeycloakAuthenticatorContext } from "../../components/Context/KeyCloakAuthenticator/KeyCloakAuthenticator";
import Role from "../../models/Role";
import DangerZone from "../../components/DangerZone/DangerZone";
import Status from "../../components/Applications/Status";

interface ApplicationPageProps {
  mode: "group" | "applications";
  groupId?: number;
}

const ApplicationsPage = ({ mode, groupId }: ApplicationPageProps) => {
  const { cluster } = useContext(ClusterContext);
  const queryClient = useQueryClient();
  const [switchingStates, setSwitchingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState<number>(0);
  const [clickedLineState, setClickedLineState] = useState(false);
  const { roles } = useContext(KeycloakAuthenticatorContext);

  const applications = usePagination(
    (page, pageSize) =>
      getApplications({
        page: page,
        pageSize: pageSize,
        isAdminPage: false,
        clusterId: cluster.id!,
      }),
    ReactQueryKeys.APPLICATIONS,
    cluster.id
  );

  const groupQuery = useQuery({
    queryKey: [ReactQueryKeys.GROUPS],
    queryFn: () => {
      return getGroupWithId(groupId!);
    },
    enabled: mode === "group" && groupId != null,
  });

  const onChangePage = (newPage: number, newPageSize: number) => {
    applications.setPage(newPage);
    applications.setPageSize(newPageSize);
  };

  const {
    data: groups,
    isLoading: groupsLoading,
    isError: groupsError,
  } = groupQuery;

  const { setLastRefreshTime, LastUpdateTimeComponent } = useLastUpdateTime();
  const generateKey = (appId: number, groupId?: number): string => {
    return groupId ? `${appId}_${groupId}` : `${appId}`;
  };
  const updateSwitchingState = (
    appId: number,
    switching: boolean,
    groupId?: number
  ) => {
    const key = generateKey(appId, groupId);
    setSwitchingStates((prev) => ({
      ...prev,
      [key]: switching,
    }));
  };

  const handleAppSwitchMutation = useMutationHandleAppSwitch(
    queryClient,
    updateSwitchingState
  );

  const handleSyncMutation = useMutationSyncApp(
    queryClient,
    setLastRefreshTime
  );

  const navigate = useNavigate();

  const handleOk = (isActive: boolean, appId: number, groupId?: number) => {
    const key = generateKey(appId!, groupId);
    setSwitchingStates((prev) => ({
      ...prev,
      [key]: true,
    }));
    handleAppSwitchMutation
      .mutateAsync({
        isActive,
        appId,
        groupId,
      })
      .then(() => {
        setSwitchingStates((prev) => ({
          ...prev,
          [key]: false,
        }));
      });
  };

  const handleAppSwitch = (appId: number) => {
    setIsWarningModalOpen(true);
    setSelectedApplicationId(appId);
  };

  const handleSync = () => {
    handleSyncMutation.mutate();
  };

  const handleRowClick = (app: Application, groupId?: number) => {
    if (mode === "group") {
      return {
        onClick: () => {
          navigate(
            `/application-details/group/${groupId}/application/${app.id}`,

            {
              state: { fromGroupsPage: true },
            }
          );
        },
        style: { cursor: "pointer" },
      };
    } else {
      return {
        onClick: () => {
          navigate(`/applications/application-details/${app.id}`);
        },
        style: { cursor: "pointer" },
      };
    }
  };

  const generateBreadcrumbItems = () => {
    const breadcrumbItems = [];
    if (mode === "group" && groupQuery.data && groupId != null) {
      const groupName = groupQuery.data.name;
      if (groupName) {
        breadcrumbItems.push(
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            title: <a onClick={() => navigate("/groups")}>Groups</a>,
          },
          {
            title: groupName,
          }
        );
      }
    }

    if (mode === "applications" && applications.items) {
      breadcrumbItems.push({
        title: "Applications",
      });
    }

    return breadcrumbItems;
  };

  const breadcrumbItems = generateBreadcrumbItems();

  const columns: ColumnsType<Application> = [
    {
      title: "Application Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => text,
    },
    {
      title: "State",
      dataIndex: "isActive",
      key: "state",
      render: (_: undefined, record: Application) => {
        const state = record.resources.some(
          (rs) => rs.currentReplicasAmount > 0
        );
        return <Status isActive={state} />;
      },
    },
    {
      title: () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            Action
            <Tooltip title="Synchronize">
              <Button icon={<SyncOutlined />} onClick={handleSync} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "isActive",
      key: "isActive",
      render: (_: undefined, record: Application) => {
        const state = record.resources?.some(
          (r) => r.currentReplicasAmount > 0
        );

        const key = generateKey(record.id, groupId);
        const isSwitching = switchingStates[key] || false;
        return (
          <Space>
            <Button
              type="default"
              onClick={async (event) => {
                event.stopPropagation();
                setClickedLineState(!state);
                handleAppSwitch(record.id);
              }}
              disabled={isSwitching}
            >
              {!state ? "On" : "Off"}
            </Button>
          </Space>
        );
      },
    },
  ];

  return (mode === "applications" ? applications.isLoading : groupsLoading) ? (
    <Loading />
  ) : (mode === "applications" ? applications.isError : groupsError) ? (
    <Error height="80vh" />
  ) : (
    <>
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <DangerZone isSensitive={cluster.sensitive} />
      <LastUpdateTimeComponent />

      <Table
        className="text"
        columns={columns}
        dataSource={
          mode === "applications" ? applications.items : groups?.popApplications
        }
        rowKey="id"
        onRow={(app: Application) => handleRowClick(app, groupId)}
        pagination={{
          total: applications.total,
          current: applications.page,
          pageSize: applications.pageSize,
          showSizeChanger: false,
          hideOnSinglePage: true,
          onChange: onChangePage,
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                roles.includes(Role.ADMIN)
                  ? "No data"
                  : "You don't have access to any application. Please contact the administrator for assistance."
              }
            />
          ),
        }}
      />
      <WarningModal
        primaryId={selectedApplicationId}
        secondaryId={groupId}
        isActive={clickedLineState}
        name={
          applications.items?.find((item) => item.id === selectedApplicationId)
            ?.name
        }
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        handleSwitch={handleOk}
      />
    </>
  );
};

export default ApplicationsPage;
