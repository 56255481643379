import { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import {
  getApplication,
  getResources,
  getResourcesNotInApplication,
} from "../../services/manageApplicationApi";
import { Application } from "../../models/Application";
import { ClusterContext } from "../../pages/HomePage/HomePage";
import { Resource } from "../../models/Resource";
import useItemSelector, { FilterOperation } from "../../hooks/useItemsSelector";

import FormMode from "../../models/Form";
import { useNavigate } from "react-router-dom";

interface ApplicationsFormProps {
  mode: FormMode;
  appId?: number;
  next: () => void;
  resources: Resource[];
  setResources: React.Dispatch<React.SetStateAction<Resource[]>>;
  applicationName: string;
  setApplicationName: React.Dispatch<React.SetStateAction<string>>;
}

const AddResourceForm = ({
  mode,
  appId,
  next,
  applicationName,
  setApplicationName,
  resources,
  setResources,
}: ApplicationsFormProps) => {
  const [selectedNamespace, setSelectedNamespace] =
    useState<String>("All Namespaces");
  const [resourceSearchString, setResourceSearchString] = useState("");

  const { cluster } = useContext(ClusterContext);
  const { Option } = Select;
  const resourcesSelector = useItemSelector<Resource>();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedNamespace !== "All Namespaces") {
      resourcesSelector.setFilterOnProperty({
        key: "namespace",
        value: selectedNamespace,
        operation: FilterOperation.EQUALS,
      });
    } else {
      resourcesSelector.clearFilterOnProperty("namespace");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNamespace]);

  useEffect(() => {
    if (resourceSearchString !== "") {
      resourcesSelector.setFilterOnProperty({
        key: "name",
        value: resourceSearchString,
        operation: FilterOperation.CONTAINS,
      });
    } else {
      resourcesSelector.clearFilterOnProperty("name");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceSearchString]);

  const allNamespaces = useMemo(
    () =>
      Array.from(
        new Set(resourcesSelector.initialItems.map((res) => res.namespace))
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourcesSelector.initialItems]
  );

  useEffect(
    () => {
      if (mode === FormMode.EDIT) {
        getApplication(appId!).then((data: Application) => {
          applicationName === "" && setApplicationName(data.name);
          if (resources.length === 0) {
            resourcesSelector.setSelectedItems(data.resources);
            setResources(data.resources);
          } else {
            resourcesSelector.setSelectedItems(data.resources);
          }
        });

        getResourcesNotInApplication(appId!).then((data) =>
          resourcesSelector.initItems(data)
        );
      } else {
        getResources(cluster.id!).then((data) =>
          resourcesSelector.initItems(data)
        );
        setApplicationName(applicationName);
        resourcesSelector.setSelectedItems(resources);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appId, mode, applicationName, resources]
  );

  const selectResource = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const selectedResourceId = event.currentTarget.id;
    resourcesSelector.selectItem(selectedResourceId);
  };

  const unSelectResource = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const selectedResourceId = event.currentTarget.id;
    resourcesSelector.unSelectItem(selectedResourceId);
  };

  const handleNext = () => {
    setApplicationName(applicationName);
    setResources(resourcesSelector.selectedItems);
    next();
  };

  return (
    <>
      <Typography.Title level={4}>Application's name</Typography.Title>
      <Input
        placeholder="Enter application name"
        value={applicationName}
        onChange={(e) => setApplicationName(e.target.value)}
        required
      />

      <Row gutter={16}>
        {/* Left column */}

        <Col span={12}>
          <Typography.Title level={4}>Available Resources</Typography.Title>

          <Card>
            <div>
              <Select
                showSearch
                value={selectedNamespace}
                onChange={(value: String) => setSelectedNamespace(value)}
                style={{ width: "35%", marginRight: "5%" }}
              >
                <Option value="All Namespaces">All Namespaces</Option>
                {allNamespaces.map((namespace, index) => (
                  <Option key={index} value={namespace}>
                    {namespace}
                  </Option>
                ))}
              </Select>

              <Input
                placeholder="Filter ressource"
                value={resourceSearchString}
                onChange={(e) => setResourceSearchString(e.target.value)}
                style={{ width: "60%" }}
              />
            </div>

            <Divider />

            {resourcesSelector.availableItems.map((res) => (
              <Button
                key={res.id}
                id={res.id.toString()}
                onClick={selectResource}
                style={{ marginRight: "8px", marginBottom: "8px" }}
              >
                {res.kind !== "Deployment"
                  ? res.name + " (" + res.kind + ")"
                  : res.name}
              </Button>
            ))}
          </Card>
        </Col>

        {/* Right column */}

        <Col span={12}>
          <Typography.Title level={4}>Selected Resources</Typography.Title>

          <Card>
            {resourcesSelector.selectedItems.length === 0 ? (
              <Typography.Text
                type="secondary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                No selected resource
              </Typography.Text>
            ) : (
              resourcesSelector.selectedItems.map((res) => (
                <Button
                  key={res.id}
                  id={res.id.toString()}
                  onClick={unSelectResource}
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                >
                  {res.kind !== "Deployment"
                    ? res.name + " (" + res.kind + ")"
                    : res.name}
                </Button>
              ))
            )}
          </Card>
        </Col>
      </Row>
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Button onClick={() => navigate("/manage-app")}>Cancel</Button>
        </div>
        <Button type="primary" onClick={handleNext}>
          Next
        </Button>
      </div>
    </>
  );
};

export default AddResourceForm;
