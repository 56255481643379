import { Button, InputNumber } from "antd";
import { useMemo, useState } from "react";
import { Resource } from "../../models/Resource";

import { CheckOutlined } from "@ant-design/icons";

const SetReplicaColumn = ({
  resource,
  handleResourceSwitch,
  groupId,
}: {
  resource: Resource;
  handleResourceSwitch: (
    replicasAmount: number,
    resourceId: number,
    groupId?: number
  ) => Promise<void>;
  groupId?: number;
}) => {
  const [tempReplicasAmount, setTempReplicasAmount] = useState<number | null>(
    null
  );
  const isButtonDisabled = useMemo(() => {
    if (tempReplicasAmount !== null) {
      return tempReplicasAmount === resource.currentReplicasAmount;
    }
  }, [tempReplicasAmount, resource.currentReplicasAmount]);

  return (
    <div>
      <InputNumber
        style={{ marginRight: "8px" }}
        min={0}
        defaultValue={resource.currentReplicasAmount}
        onChange={(value) => {
          if (typeof value === "number") {
            setTempReplicasAmount(value);
          }
        }}
      />
      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={() => {
          if (tempReplicasAmount !== null) {
            handleResourceSwitch(tempReplicasAmount, resource.id, groupId);
          }
        }}
        disabled={isButtonDisabled}
      />
    </div>
  );
};

export default SetReplicaColumn;
