import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, FloatButton, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import Error from "../../components/LoadingAndError/Error";
import Loading from "../../components/LoadingAndError/Loading";
import DeleteModal from "../../components/Modal/DeleteModal";
import usePagination from "../../hooks/usePagination";
import { Application } from "../../models/Application";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import { useMutationDeleteApplication } from "../../mutations/useMutationApplication";
import { useSync } from "../../mutations/useMutationCluster";
import { getApplications } from "../../services/listApplicationsApi";
import { ClusterContext } from "../HomePage/HomePage";
import DangerZone from "../../components/DangerZone/DangerZone";

const ManageAppsPage = () => {
  const { cluster } = React.useContext(ClusterContext);
  const queryClient = useQueryClient();
  const { setLastRefreshTime, LastUpdateTimeComponent } = useLastUpdateTime();
  const [appId, setAppId] = useState<number | undefined>(undefined);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const navigate = useNavigate();

  const applications = usePagination(
    (page, pageSize) =>
      getApplications({
        page: page,
        pageSize: pageSize,
        isAdminPage: true,
        clusterId: cluster.id!,
      }),

    ReactQueryKeys.APPLICATIONS,
    cluster.id!
  );
  const onChangePage = (newPage: number, newPageSize: number) => {
    applications.setPage(newPage);
  };

  const handleSyncMutation = useSync(queryClient, setLastRefreshTime);

  const handleSync = () => {
    handleSyncMutation.mutate();
    setLastRefreshTime(new Date());
  };

  const handleRemoveAppMutation = useMutationDeleteApplication(queryClient);

  const handleRemoveApp = (appId: number) => {
    handleRemoveAppMutation.mutate(appId);
  };

  const columns: ColumnsType<Application> = [
    {
      title: "Application Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip title="Synchronize">
            <Button icon={<SyncOutlined />} onClick={handleSync} />
          </Tooltip>
        </div>
      ),
      key: "actions",
      render: (_: unknown, record: Application) => (
        <>
          <Button
            onClick={() => {
              navigate(`/edit-app/${record.name}`, {
                state: { appId: record.id },
              });
            }}
          >
            Edit
          </Button>
          <span style={{ margin: "0 10px" }}></span>
          <Button
            type="primary"
            onClick={() => {
              setAppId(record.id);
              setIsModalDeleteOpen(true);
            }}
          >
            Remove
          </Button>
        </>
      ),
      align: "right" as const,
    },
  ];
  return applications.isLoading ? (
    <Loading />
  ) : applications.isError ? (
    <Error height="80vh" />
  ) : (
    <>
      <LastUpdateTimeComponent />
      <DangerZone isSensitive={cluster.sensitive} />
      <Table
        columns={columns}
        dataSource={applications.items}
        rowKey="id"
        pagination={{
          total: applications.total,
          current: applications.page,
          pageSize: applications.pageSize,
          showSizeChanger: false,
          hideOnSinglePage: true,
          onChange: onChangePage,
        }}
      />
      <FloatButton
        shape="circle"
        tooltip="Add Application"
        type="primary"
        style={{ right: 94, width: 60, height: 60 }}
        icon={<PlusOutlined />}
        onClick={() => {
          navigate(`/add-app/`);
        }}
      />
      <DeleteModal
        id={appId}
        isModalDeleteOpen={isModalDeleteOpen}
        setIsModalDeleteOpen={setIsModalDeleteOpen}
        handleRemove={handleRemoveApp}
      />
    </>
  );
};

export default ManageAppsPage;
