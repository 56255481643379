import axios from "axios";
import { Application } from "../models/Application";
import { Resource } from "../models/Resource";
import { Ingress } from "../models/Ingress";

export const getResources = async (clusterId: number): Promise<Resource[]> => {
  const result = await axios.get(`${Config.apiBaseUrl}/resources/cluster`, {
    params: {
      clusterId: clusterId,
    },
  });
  return result.data;
};

export const getResourcesNotInApplication = async (
  appId: number
): Promise<Resource[]> => {
  const result = await axios.get(
    `${Config.apiBaseUrl}/resources/popApplication`,
    {
      params: {
        popApplicationId: appId,
      },
    }
  );
  return result.data;
};

export const getIngresses = async (clusterId: number): Promise<Ingress[]> => {
  const result = await axios.get(`${Config.apiBaseUrl}/ingresses`, {
    params: {
      clusterId: clusterId,
    },
  });
  return result.data;
};

export const getIngressesNotInApplication = async (
  appId: number
): Promise<Ingress[]> => {
  const result = await axios.get(
    `${Config.apiBaseUrl}/ingresses/applications`,
    {
      params: {
        appId: appId,
      },
    }
  );
  return result.data;
};

export const getApplication = async (appId: number): Promise<Application> => {
  const result = await axios.get(`${Config.apiBaseUrl}/applications/${appId}`);
  return result.data;
};

export const saveApplication = ({
  appName,
  resourcesIds,
  ingressIds,
  clusterId,
}: {
  appName: string;
  resourcesIds: number[];
  ingressIds: number[];
  clusterId: number;
}) =>
  axios.post(`${Config.apiBaseUrl}/applications`, {
    name: appName,
    resourcesIds: resourcesIds,
    clusterId: clusterId,
    ingressesIds: ingressIds,
  });

export const editApplication = ({
  appId,
  appName,
  resourcesIds,
  ingressIds,
}: {
  appId: number;
  appName: string;
  resourcesIds: number[];
  ingressIds: number[];
}) => {
  return axios.patch(`${Config.apiBaseUrl}/applications/${appId}`, {
    name: appName,
    resourcesIds: resourcesIds,
    ingressesIds: ingressIds,
  });
};
