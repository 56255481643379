import axios from "axios";
import { Group } from "../models/Group";
import { Page } from "../models/Page";

export const fetchGroups = async ({
  page,
  pageSize,
  isAdminPage,
  clusterId,
}: {
  page: number;
  pageSize: number;
  isAdminPage: boolean;
  clusterId?: number;
}): Promise<Page<Group>> => {
  const result = await axios.get<Page<Group>>(
    `${Config.apiBaseUrl}/groups?page=${page}&size=${pageSize}`,
    {
      params: {
        clusterId: clusterId,
        isAdminPage: isAdminPage,
      },
    }
  );
  return result.data;
};

export const getGroupWithId = async (groupId: number): Promise<Group> => {
  const result = await axios.get<Group>(
    `${Config.apiBaseUrl}/groups/${groupId}`
  );
  return result.data;
};

export const changeStateGroup = async ({
  isActive,
  groupId,
}: {
  isActive: boolean;
  groupId: number;
}) => {
  await axios.post(`${Config.apiBaseUrl}/groups/${groupId}/status`, {
    isActive,
  });
};

export const syncBackendWithDB = async () => {
  await axios.post(`${Config.apiBaseUrl}/sync`);
};

export const removeGroup = async (groupId: number) => {
  await axios.delete(`${Config.apiBaseUrl}/groups/${groupId}`, {});
};
