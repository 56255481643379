import { useState } from "react";

export enum FilterOperation {
  EQUALS,
  CONTAINS,
}

type FilterType<T> = {
  key: keyof T;
  value: unknown;
  operation: FilterOperation;
};

const useItemSelector = <T extends { id: number }>() => {
  const [availableItems, setAvailableItems] = useState<T[]>([]);
  const [initialItems, setInitialItems] = useState<T[]>([]);
  const [selectedItems, _setSelectedItems] = useState<T[]>([]);
  const [currentFilters, setCurrentFilters] = useState<FilterType<T>[]>([]);

  const initItems = (items: T[]) => {
    setAvailableItems(items);
    setInitialItems(items);
  };

  const selectItem = (id: string) => {
    const clickedItem = availableItems.find((res) => res.id.toString() === id);

    _setSelectedItems((prev) => [...prev, clickedItem!]);
    setAvailableItems((prev) => prev.filter((res) => res.id.toString() !== id));
  };

  const unSelectItem = (id: string) => {
    const clickedItem = selectedItems.find((res) => res.id.toString() === id);

    if (filterWithAllCurrentFilters(clickedItem!, currentFilters)) {
      setAvailableItems((prev) => [...prev, clickedItem!]);
    }
    if (initialItems.find((item) => item.id.toString() === id) === undefined) {
      setInitialItems((prev) => [...prev, clickedItem!]);
    }
    _setSelectedItems((prev) => prev.filter((res) => res.id.toString() !== id));
  };

  const resetSelectedItems = () => {
    setSelectedItems([]);
    setAvailableItems(initialItems);
  };

  const setSelectedItems = (newSelected: T[]) => {
    _setSelectedItems(newSelected);
    const newAvalaibleItems = availableItems.filter((item) =>
      filterSelectedNotRemainsInAvailable(item, newSelected)
    );
    setAvailableItems(newAvalaibleItems);
  };

  const filterSelectedNotRemainsInAvailable = (item: T, newSelected: T[]) => {
    return (
      newSelected.find((availableItem) => availableItem.id === item.id) ===
      undefined
    );
  };

  const setFilterOnProperty = (filter: FilterType<T>) => {
    const existingCurrentFilterIndex = currentFilters.findIndex(
      (item) => item.key === filter.key
    );
    const newCurrentFilters = [...currentFilters];
    if (existingCurrentFilterIndex !== -1) {
      newCurrentFilters[existingCurrentFilterIndex] = filter;
      setCurrentFilters(newCurrentFilters);
    } else {
      newCurrentFilters.push(filter);
      setCurrentFilters(newCurrentFilters);
    }
    setAvailableItems(
      initialItems.filter((item) =>
        filterWithAllCurrentFilters(item, newCurrentFilters)
      )
    );
  };

  const clearFilterOnProperty = (key: keyof T) => {
    const newCurrentFilters = currentFilters.filter(
      (filter) => filter.key !== key
    );
    setAvailableItems(
      initialItems.filter((item) =>
        filterWithAllCurrentFilters(item, newCurrentFilters)
      )
    );
    setCurrentFilters(newCurrentFilters);
  };

  const filterWithAllCurrentFilters = (item: T, filters: FilterType<T>[]) => {
    if (filters.length === 0) return true;
    return filters.every((filter) => {
      switch (filter.operation) {
        case FilterOperation.EQUALS:
          return item[filter.key] === filter.value;
        case FilterOperation.CONTAINS:
          return (item[filter.key] as string).includes(filter.value as string);
        default:
          return false;
      }
    });
  };

  return {
    //Loads items initial items
    initItems,
    //Pre-select items, if needed when edit mode, will remove the selected from the available list
    setSelectedItems,
    //Select an item, will move from availableItems to selectedItems
    selectItem,
    //Unselect an item, will move from selectedItems. If filters are likely to diplay it, will add it to available items,
    //will also add if needed into the initialItems if the item was pre-selected
    unSelectItem,
    //Reset selected items and replace available items with the initial items
    resetSelectedItems,
    //Add filter on a property that can be EQUALS or CONTAINS, if a filter on this key exists, will replace it, filters on
    //other properties won't be impacted
    setFilterOnProperty,
    //Remove filter on a property, filter on other properties won't be impacted
    clearFilterOnProperty,
    //All items that the hook need to deal with
    initialItems,
    //Item that can be selected
    availableItems,
    //Items that are selected can from the pre-select function or a manual selection
    selectedItems,
  };
};

export default useItemSelector;
