import { Col, Row, Select } from "antd";
import React, { useMemo } from "react";
import { Cluster } from "../../models/Cluster";
import { useLocation } from "react-router-dom";

const SynchronizeList = ({
  clusters,
  currentCluster,
  setCurrentCluster,
}: {
  clusters: Cluster[];
  currentCluster: {
    id: number | null;
    name: string | null;
    sensitive: boolean;
  };
  setCurrentCluster: React.Dispatch<
    React.SetStateAction<{
      id: number | null;
      name: string | null;
      sensitive: boolean;
    }>
  >;
}) => {
  const { Option } = Select;
  const location = useLocation();
  const current =
    location.pathname === "/" || location.pathname === ""
      ? "/"
      : "/" + location.pathname.split("/")[1];

  const isDisplayed = useMemo(() => {
    return current !== "/manage-users";
  }, [current]);

  return isDisplayed ? (
    <Row align="middle" gutter={10} justify="end">
      <Col>
        {currentCluster.id === null ? (
          <div style={{ color: "red" }}>Please add a cluster</div>
        ) : null}
      </Col>
      <Col>
        <Select
          style={{ width: "100%" }}
          placeholder="Select a cluster"
          value={currentCluster.name}
          onChange={(value) => {
            const selectedCluster = clusters.find(
              (cluster) => cluster.name === value
            );
            if (selectedCluster) {
              setCurrentCluster({
                id: selectedCluster.id,
                name: selectedCluster.name,
                sensitive: selectedCluster.sensitive,
              });
            }
          }}
        >
          {clusters.map((cluster) => (
            <Option key={cluster.id} value={cluster.name}>
              {cluster.name}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  ) : (
    <div
      style={{ marginLeft: "-5px", marginRight: "-5px", height: "32px" }}
    ></div>
  );
};

export default SynchronizeList;
