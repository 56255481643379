import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Divider, Input, Row, Typography } from "antd";
import FormMode from "../../models/Form";
import { Application } from "../../models/Application";
import Error from "../LoadingAndError/Error";
import { ClusterContext } from "../../pages/HomePage/HomePage";
import { getApplications } from "../../services/listApplicationsApi";
import {
  getGroup,
  getPopApplicationsNotInCluster,
} from "../../services/manageGroupApi";
import { Group } from "../../models/Group";

interface GroupFormProps {
  groupId?: number;
  mode: FormMode;
  groupName: string;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  selectedApplications: Application[];
  setSelectedApplications: React.Dispatch<React.SetStateAction<Application[]>>;
}

const AddEditGroupForm = ({
  groupId,
  mode,
  groupName,
  setGroupName,
  selectedApplications,
  setSelectedApplications,
}: GroupFormProps) => {
  const [applicationSearchString, setApplicationSearchString] = useState("");
  const [availablePopApplications, setAvailablePopApplications] = useState<
    Application[]
  >([]);
  const { cluster } = useContext(ClusterContext);

  const availablePopApplicationsFilter = useMemo(() => {
    return availablePopApplications
      .filter((res) => !selectedApplications.includes(res))
      .filter((res) => res.name.includes(applicationSearchString));
  }, [availablePopApplications, applicationSearchString, selectedApplications]);

  useEffect(() => {
    if (cluster.id !== null && groupId == null) {
      getApplications({
        page: 0,
        pageSize: 1000,
        isAdminPage: true,
        clusterId: cluster.id,
      }).then((data) => setAvailablePopApplications(data.content));
    } else if (cluster.id !== null) {
      getPopApplicationsNotInCluster(groupId!).then((data) =>
        setAvailablePopApplications(data)
      );
    }
  }, [cluster.id, groupId]);

  useEffect(() => {
    if (mode === FormMode.EDIT && groupId) {
      getGroup(groupId).then((data: Group) => {
        setGroupName(data.name);
        setSelectedApplications(data.popApplications);
      });
    } else {
      setGroupName("");
      setSelectedApplications([]);
    }
    // eslint-disable-next-line
  }, [groupId, mode]);

  const selectPopApplications = (event: any) => {
    const selectedPopApplicationId = event.currentTarget.id;
    const clickedPopApplication = availablePopApplications.find(
      (res) => res.id.toString() === selectedPopApplicationId
    );

    setSelectedApplications((prev) => [...prev, clickedPopApplication!]);
    setAvailablePopApplications((prev) =>
      prev.filter((res) => res.id.toString() !== selectedPopApplicationId)
    );
  };

  const unSelectPopApplication = (event: any) => {
    const selectedPopApplicationId = event.currentTarget.id;
    const clickedPopApplication = selectedApplications.find(
      (res) => res.id.toString() === selectedPopApplicationId
    );

    setAvailablePopApplications((prev) => [...prev, clickedPopApplication!]);
    setSelectedApplications((prev) =>
      prev.filter((res) => res.id.toString() !== selectedPopApplicationId)
    );
  };

  return (
    <>
      <Input
        placeholder="Enter group name"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
      />

      <Row gutter={16}>
        {/* Left column */}
        <Col span={12}>
          <Typography.Title level={4}>Available Applications</Typography.Title>
          <Card>
            <Input
              placeholder="Filter application"
              value={applicationSearchString}
              onChange={(e) => setApplicationSearchString(e.target.value)}
            />

            <Divider />

            {availablePopApplicationsFilter.length !== 0 ? (
              availablePopApplicationsFilter.map((res) => (
                <Button
                  key={res.id}
                  id={res.id.toString()}
                  onClick={selectPopApplications}
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                >
                  {res.name}
                </Button>
              ))
            ) : (
              <Error height="10vh" />
            )}
          </Card>
        </Col>
        {/* Right column */}

        <Col span={12}>
          <Typography.Title level={4}>Selected Applications</Typography.Title>
          <Card>
            {selectedApplications.length !== 0 ? (
              selectedApplications.map((res) => (
                <Button
                  key={res.id}
                  id={res.id.toString()}
                  onClick={unSelectPopApplication}
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                >
                  {res.name}
                </Button>
              ))
            ) : (
              <Error height="10vh" />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddEditGroupForm;
