import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Divider, Row, Typography } from "antd";
import { Application } from "../../models/Application";
import Error from "../LoadingAndError/Error";
import { ClusterContext } from "../../pages/HomePage/HomePage";
import { getApplications } from "../../services/listApplicationsApi";
import { Group } from "../../models/Group";
import { fetchGroups } from "../../services/listGroupApi";
import { getUserById } from "../../services/manageUserApi";
import { User } from "../../models/User";

interface UserFormProps {
  userId?: number;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setKeycloakId: React.Dispatch<React.SetStateAction<string>>;
  selectedPopApplications: Application[];
  setSelectedPopApplications: React.Dispatch<
    React.SetStateAction<Application[]>
  >;
  selectedGroups: Group[];
  setSelectedGroups: React.Dispatch<React.SetStateAction<Group[]>>;
}

const EditUserForm = ({
  userId,
  setUserName,
  setEmail,
  setKeycloakId,
  selectedPopApplications,
  setSelectedPopApplications,
  selectedGroups,
  setSelectedGroups,
}: UserFormProps) => {
  const { cluster } = useContext(ClusterContext);
  const [availablePopApplications, setAvailablePopApplications] = useState<
    Application[]
  >([]);
  const [availableGroups, setAvailableGroups] = useState<Group[]>([]);
  const [availablePopApplicationsFilter, setAvailablePopApplicationsFilter] =
    useState<Application[]>([]);
  const [availableGroupsFilter, setAvailableGroupsFilter] = useState<Group[]>(
    []
  );

  useEffect(() => {
    setAvailablePopApplicationsFilter(
      availablePopApplications.filter(
        (res) =>
          !selectedPopApplications.some(
            (selectedRes) => selectedRes.id === res.id
          )
      )
    );
    setAvailableGroupsFilter(
      availableGroups.filter(
        (res) =>
          !selectedGroups.some((selectedRes) => selectedRes.id === res.id)
      )
    );
  }, [
    availableGroups,
    availablePopApplications,
    selectedGroups,
    selectedPopApplications,
  ]);

  useEffect(() => {
    getApplications({
      page: 0,
      pageSize: 1000,
      isAdminPage: true,
      clusterId: cluster.id!,
    }).then((data) => setAvailablePopApplications(data.content));
    fetchGroups({
      page: 0,
      pageSize: 1000,
      isAdminPage: true,
      clusterId: cluster.id!,
    }).then((data) => setAvailableGroups(data.content));
  }, [cluster.id, userId]);

  useEffect(() => {
    if (userId !== undefined) {
      getUserById(userId).then((data: User) => {
        setUserName(data.name);
        setEmail(data.email);
        setKeycloakId(data.keycloackId);
        setSelectedPopApplications(data.popApplications);
        setSelectedGroups(data.groups);
      });
      getApplications({
        page: 0,
        pageSize: 1000,
        isAdminPage: true,
        clusterId: cluster.id!,
      }).then((data) => setAvailablePopApplications(data.content));
    } else {
      setSelectedPopApplications([]);
      setSelectedGroups([]);
    }
    // eslint-disable-next-line
  }, [userId]);

  const selectPopApplications = (event: React.MouseEvent) => {
    const selectedPopApplicationId = event.currentTarget.id;
    const clickedPopApplication = availablePopApplications.find(
      (res) => res.id.toString() === selectedPopApplicationId
    );

    if (clickedPopApplication) {
      setSelectedPopApplications((prev) => {
        if (Array.isArray(prev)) {
          return [...prev, clickedPopApplication!];
        } else {
          return [clickedPopApplication!];
        }
      });
      setAvailablePopApplications((prev) =>
        prev.filter((res) => res.id.toString() !== selectedPopApplicationId)
      );
    }
  };

  const selectGroups = (event: React.MouseEvent) => {
    const selectedGroupId = event.currentTarget.id;
    const clickedGroup = availableGroups.find(
      (res) => res.id.toString() === selectedGroupId
    );
    if (clickedGroup) {
      setSelectedGroups((prev) => {
        if (Array.isArray(prev)) {
          return [...prev, clickedGroup!];
        } else {
          return [clickedGroup!];
        }
      });
      setAvailableGroups((prev) =>
        prev.filter((res) => res.id.toString() !== selectedGroupId)
      );
    }
  };

  const unSelectPopApplication = (event: React.MouseEvent) => {
    const selectedPopApplicationId = event.currentTarget.id;
    const clickedPopApplication = selectedPopApplications.find(
      (res) => res.id.toString() === selectedPopApplicationId
    );

    if (clickedPopApplication) {
      setAvailablePopApplications((prev) => {
        if (
          Array.isArray(prev) &&
          !prev.some((res) => res.id === clickedPopApplication.id)
        ) {
          return [...prev, clickedPopApplication!];
        } else {
          return prev;
        }
      });
      setSelectedPopApplications((prev) =>
        prev.filter((res) => res.id.toString() !== selectedPopApplicationId)
      );
    }
  };

  const unSelectGroup = (event: React.MouseEvent) => {
    const selectedGroupId = event.currentTarget.id;
    const clickedGroup = selectedGroups.find(
      (res) => res.id.toString() === selectedGroupId
    );
    if (clickedGroup) {
      setAvailableGroups((prev) => {
        if (
          Array.isArray(prev) &&
          !prev.some((res) => res.id === clickedGroup.id)
        ) {
          return [...prev, clickedGroup!];
        } else {
          return prev;
        }
      });
      setSelectedGroups((prev) =>
        prev.filter((res) => res.id.toString() !== selectedGroupId)
      );
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Typography.Title level={4}>Available Applications</Typography.Title>
          <Card>
            {availablePopApplicationsFilter.length !== 0 ? (
              availablePopApplicationsFilter.map((res) => (
                <Button
                  key={res.id}
                  id={res.id.toString()}
                  onClick={selectPopApplications}
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                >
                  {res.name}
                </Button>
              ))
            ) : (
              <Error height="10vh" />
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Typography.Title level={4}>Selected Applications</Typography.Title>
          <Card>
            {selectedPopApplications.length !== 0 ? (
              selectedPopApplications.map((res) => (
                <Button
                  key={res.id}
                  id={res.id.toString()}
                  onClick={unSelectPopApplication}
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                >
                  {res.name}
                </Button>
              ))
            ) : (
              <Error height="10vh" />
            )}
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Typography.Title level={4}>Available Groups</Typography.Title>
          <Card>
            {availableGroupsFilter.length !== 0 ? (
              availableGroupsFilter.map((res) => (
                <Button
                  key={res.id}
                  id={res.id.toString()}
                  onClick={selectGroups}
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                >
                  {res.name}
                </Button>
              ))
            ) : (
              <Error height="10vh" />
            )}
          </Card>
        </Col>

        <Col span={12}>
          <Typography.Title level={4}>Selected Groups</Typography.Title>
          <Card>
            {selectedGroups.length !== 0 ? (
              selectedGroups.map((res) => (
                <Button
                  key={res.id}
                  id={res.id.toString()}
                  onClick={unSelectGroup}
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                >
                  {res.name}
                </Button>
              ))
            ) : (
              <Error height="10vh" />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditUserForm;
